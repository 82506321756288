import { Select } from 'antd';
import React from 'react';

const SelectComponent = (props) => {
  const { placeholder = '', children, ...rest } = props;

  return (
    <Select placeholder={`Select ${placeholder}`} allowClear {...rest}>
      {children}
    </Select>
  );
};

export default SelectComponent;
