import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CHAPLAINS = gql`
  query chaplains($data: ChaplainFilter!) {
    chaplains(data: $data) {
      total
      data {
        id
        firstName
        lastName
        email
        refId
        companies {
          id
          name
        }
        phoneNumber
      }
    }
  }
`;
