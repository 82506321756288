import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COMPANIES = gql`
  query companies($data: CompanyFilter!) {
    companies(data: $data) {
      total
      data {
        id
        name
        refData
        refId
        locationId
      }
    }
  }
`;
