import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ORGANIZATIONS = gql`
  query organizations($data: ResourceFilter!) {
    organizations(data: $data) {
      total
      data {
        id
        name
        organizationId
      }
    }
  }
`;
