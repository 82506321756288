import { LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Card, Form, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { fileUpload } from '../../../common/utils';
import ResourceComment from '../components/ResourceComment';
import ResourceForm from '../components/ResourceForm';
import { GET_SIGNED_URL, UPDATE_RESOURCE } from '../graphql/Mutations';
import { RESOURCES, RESOURCES_RATINGS } from '../graphql/Queries';

const ResourceEdit = (props) => {
  const { history } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fileObj, setFileObj] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const { data: ratingData } = useQuery(RESOURCES_RATINGS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        resourceId: id
      }
    }
  });

  const { loading: resourceDataLoading, data } = useQuery(RESOURCES, {
    variables: { data: { where: { _id: id } } },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [updateResourceMutate] = useMutation(UPDATE_RESOURCE, {
    onError() {}
  });
  const [fetchSignedUrl] = useMutation(GET_SIGNED_URL);

  const onResourceSubmitFinish = async (values) => {
    setLoading(true);
    const content = values?.content?.map((contentItem) => ({
      description: contentItem?.description,
      language: contentItem?.language,
      title: contentItem?.title,
      url: contentItem?.url
    }));
    const variables = {
      where: id,
      data: {
        type: values?.type,
        organizationIds: values?.organizationIds,
        isActive: values?.isActive,
        companyIds: values?.companyIds,
        ...(values?.order && { order: parseInt(values?.order, 10) }),
        content: content,
        thumbnail: imageUrl || data?.resources?.data?.[0]?.thumbnail
      }
    };

    if (fileObj) {
      const imageName = fileObj?.name;
      const response = await fetchSignedUrl({
        variables: {
          data: {
            key: imageName,
            contentType: fileObj?.type
          }
        }
      });
      if (response) {
        const signedImageUrl =
          response?.data?.getSignedPutUrlResources?.signedUrl;
        fileUpload(signedImageUrl, fileObj).then(async () => {
          variables.data.thumbnail =
            response?.data?.getSignedPutUrlResources?.getUrl;
          setFileObj();
          try {
            const res = await updateResourceMutate({ variables });
            if (res?.data?.updateResource) {
              history.goBack();
              setLoading(false);
            }
            return res;
          } catch (error) {
            return error;
          }
        });
      }
    } else {
      try {
        const res = await updateResourceMutate({ variables });
        if (res?.data?.updateResource) {
          history.goBack();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        return error;
      }
    }
  };

  return (
    <>
      <Card>
        <h3 className="font-24 font-weight-600">
          <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
          <span>Edit Resource</span>
        </h3>
        <hr />
        {resourceDataLoading ? (
          <div className="d-flex justify-center align-center webinar-form-loading">
            <Spin size="large" />
          </div>
        ) : (
          <ResourceForm
            form={form}
            isEdit
            setFileObj={setFileObj}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            resourceData={data?.resources?.data?.[0] || {}}
            onResourceSubmitFinish={onResourceSubmitFinish}
            updateResourceLoading={loading}
          />
        )}
      </Card>
      {ratingData?.resourceRattings?.data?.length > 0 && (
        <div className="mt-10">
          <ResourceComment data={ratingData?.resourceRattings?.data} />
        </div>
      )}
    </>
  );
};

export default withRouter(ResourceEdit);
