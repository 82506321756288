import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, message, Spin } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = () => {
  const history = useHistory();

  const [
    changePasswordMutate,
    { loading: changePasswordLoading }
  ] = useMutation(CHANGE_PASSWORD, {
    onError() {}
  });

  const onFinish = async (values) => {
    try {
      const variables = {
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword
      };
      const response = await changePasswordMutate({ variables });
      if (response) {
        history.replace(ROUTES.MAIN);
      }
    } catch (e) {
      if (get(e, 'response.data.message')) {
        message.error(e.response.data.message);
      } else if (e && e.message) {
        message.error(e.message);
      } else {
        message.error('Something went wrong');
      }
    }
  };

  return (
    <div className="gx-login-container">
      <Spin
        spinning={changePasswordLoading}
        wrapperClassName="gx-login-content"
      >
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Form
          name="change-password"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="oldPassword" rules={[required]}>
            <InputComponent
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Old Password"
            />
          </Form.Item>
          <Form.Item name="newPassword" rules={[required]}>
            <InputComponent
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="retype-password"
            rules={[
              required,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value !== getFieldValue('newPassword')) {
                    return Promise.reject(new Error('Passwords do not match'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <InputComponent
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Confirm New Password"
            />
          </Form.Item>
          <div className="d-flex">
            <Form.Item>
              <Button
                loading={changePasswordLoading}
                type="primary"
                className="mr-5"
                htmlType="submit"
              >
                Change Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ChangePassword;
