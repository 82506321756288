import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNED_URL = gql`
  mutation getSignedPutUrlNewsLetter($data: SignedUrlNewsLetterDataInput!) {
    getSignedPutUrlNewsLetter(data: $data) {
      signedUrl
      fileName
      getUrl
    }
  }
`;

export const UPDATE_NEWSLETTER = gql`
  mutation updateNewsLetter(
    $data: [newsLettersInput]!
    $where: UpdateNewsLetterInput!
  ) {
    updateNewsLetter(data: $data, where: $where) {
      message
      data {
        name
        organizationId
        newsLetters {
          id
          language
          url
        }
      }
    }
  }
`;
