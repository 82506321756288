import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_RESOURCE = gql`
  mutation deleteResource($where: ID!) {
    deleteResource(where: $where) {
      status
      message
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation updateResource($where: ID!, $data: UpdateResourceInput!) {
    updateResource(where: $where, data: $data) {
      message
      data {
        id
      }
    }
  }
`;

export const CREATE_RESOURCE = gql`
  mutation createResource($data: CreateResourceInput!) {
    createResource(data: $data) {
      message
      data {
        id
        thumbnail
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation getSignedPutUrlResources($data: SignedUrlDataInput!) {
    getSignedPutUrlResources(data: $data) {
      signedUrl
      fileName
      getUrl
    }
  }
`;
