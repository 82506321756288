import { Modal, Tag } from 'antd';
import { map } from 'lodash';
import React from 'react';

const CompaniesModal = ({ showModal, setShowModal, companiesData }) => {
  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <Modal
      onCancel={handleCancel}
      footer={null}
      centered
      visible={showModal}
      title="Remaining Companies"
      wrapClassName="companies-modal"
    >
      {map(companiesData, (item) => {
        return (
          <Tag className="mb-5" key={item?.id}>
            {item?.name}
          </Tag>
        );
      })}
    </Modal>
  );
};

export default CompaniesModal;
