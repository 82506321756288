import { LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Card, Form } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { fileUpload } from '../../../common/utils';
import ResourceForm from '../components/ResourceForm';
import { CREATE_RESOURCE, GET_SIGNED_URL } from '../graphql/Mutations';

const ResourceAdd = (props) => {
  const { history } = props;
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [fileObj, setFileObj] = useState();
  const [loading, setLoading] = useState(false);

  const [createResourceMutate] = useMutation(CREATE_RESOURCE, {
    onError() {}
  });

  const [fetchSignedUrl] = useMutation(GET_SIGNED_URL);

  const onResourceSubmitFinish = async (values) => {
    setLoading(true);
    const content = values?.content?.map((contentItem) => {
      return {
        description: contentItem?.description,
        language: contentItem?.language,
        title: contentItem?.title,
        url: contentItem?.url
      };
    });

    const variables = {
      data: {
        type: values?.type,
        organizationIds: values?.organizationIds,
        isActive: values?.isActive,
        companyIds: values?.companyIds,
        ...(values?.order && { order: parseInt(values?.order, 10) }),
        content: content,
        thumbnail: imageUrl
      }
    };

    if (fileObj) {
      const imageName = fileObj?.name;
      const response = await fetchSignedUrl({
        variables: {
          data: {
            key: imageName,
            contentType: fileObj?.type
          }
        }
      });
      if (response) {
        const signedImageUrl =
          response?.data?.getSignedPutUrlResources?.signedUrl;

        fileUpload(signedImageUrl, fileObj).then(async () => {
          variables.data.thumbnail =
            response?.data?.getSignedPutUrlResources?.getUrl;

          setFileObj();
          try {
            const res = await createResourceMutate({ variables });
            if (res?.data?.createResource) {
              setLoading(false);
              history.goBack();
            }
            return res;
          } catch (error) {
            return error;
          }
        });
      }
    } else {
      try {
        const res = await createResourceMutate({ variables });
        if (res?.data?.createResource) {
          setLoading(false);
          history.goBack();
        }
        return res;
      } catch (error) {
        setLoading(false);
        return error;
      }
    }
  };

  return (
    <Card>
      <h3 className="font-24 font-weight-600">
        <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
        <span>Add Resource</span>
      </h3>
      <hr />
      <ResourceForm
        form={form}
        resourceData={{}}
        onResourceSubmitFinish={onResourceSubmitFinish}
        createResourceLoading={loading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFileObj={setFileObj}
      />
    </Card>
  );
};

export default withRouter(ResourceAdd);
