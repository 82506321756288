import { Input } from 'antd';
import React from 'react';

const InputComponent = (props) => {
  const { placeholder = '', type, ...rest } = props;

  if (type === 'password') {
    return <Input.Password placeholder={`Enter ${placeholder}`} {...rest} />;
  }

  if (type === 'textArea') {
    return <Input.TextArea placeholder={`Enter ${placeholder}`} {...rest} />;
  }

  return <Input placeholder={`Enter ${placeholder}`} {...rest} />;
};

export default InputComponent;
