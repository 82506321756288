import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { debounce, forEach, map, trim } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import { GET_LANGUAGES } from '../../language/graphql/Queries';
import { ORGANIZATIONS } from '../../resources/graphql/Queries';
import { CREATE_UILABEL, UPDATE_UILABEL } from '../graphql/Mutation';

const { required } = formValidatorRules;
const UiLabelForm = ({ isEdit, editData, dataLoading }) => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [organizationData, setOrganizationData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [languageData, setLanguageData] = useState([]);
  const [languageLoading, setLanguageLoading] = useState(false);
  const [languageSearchFlag, setLanguageSearchFlag] = useState(false);
  const [languageIsEnd, setLanguageIsEnd] = useState(false);
  const [languageDebounceCall, setLanguageDebounceCall] = useState(0);
  const initialLanguageFilter = {
    skip: 0,
    limit: 10
  };
  let languageDebounceJob;

  const [
    fetchOrganizations,
    { loading: organizationsDataLoading }
  ] = useLazyQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { data: { skip: 0 } },
    onCompleted(res) {
      setOrganizationData(
        map(res?.organizations?.data, (item) => ({
          label: item?.name,
          value: item?.id
        }))
      );
    },
    onError() {}
  });

  const [fetchLanguages] = useLazyQuery(GET_LANGUAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        skip: 0,
        limit: 10
      }
    },
    onCompleted: (res) => {
      if (res?.abConstants?.data?.length < SKIP_RECORD) {
        setLanguageIsEnd(true);
      }
      if (languageSearchFlag) {
        setLanguageData([...res?.abConstants?.data]);
      } else {
        setLanguageData([...languageData, ...res?.abConstants?.data]);
      }
      setLanguageLoading(false);
    },
    onError() {
      setLanguageLoading(false);
    }
  });

  const onLanguageScroll = (event) => {
    setLanguageSearchFlag(false);
    if (languageDebounceJob) {
      languageDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    languageDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !languageIsEnd) {
        setLanguageLoading(true);
        setLanguageDebounceCall((prevState) => prevState + 1);
        fetchLanguages({
          variables: {
            where: {
              ...initialLanguageFilter,
              skip: (languageDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    languageDebounceJob();
  };

  const handleLanguageChange = (value) => {
    setLanguageSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setLanguageLoading(true);
      fetchLanguages({
        variables: {
          where: {
            ...initialLanguageFilter,
            search: value
          }
        }
      });
    } else {
      setLanguageLoading(false);
      fetchLanguages({
        variables: {
          where: {
            ...initialLanguageFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLanguageHandler = useCallback(
    debounce(handleLanguageChange, 500),
    []
  );

  const handleLanguageClear = () => {
    setLanguageData([]);
    fetchLanguages({
      variables: { where: initialLanguageFilter }
    });
  };

  useEffect(() => {
    fetchLanguages();
    if (editData) {
      fetchOrganizations({
        variables: {
          data: {
            skip: 0,
            name: editData?.organizationName
          }
        }
      });
    } else {
      fetchOrganizations({
        variables: {
          data: {
            skip: 0
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  useEffect(() => {
    if (editData && organizationData?.[0]?.value) {
      form.setFieldsValue({ organizationIds: organizationData?.[0]?.value });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, editData]);

  const [createUiLabel, { loading: createLoading }] = useMutation(
    CREATE_UILABEL,
    {
      onError() {}
    }
  );

  const [updateUiLabel, { loading: updateLoading }] = useMutation(
    UPDATE_UILABEL,
    {
      onError() {}
    }
  );

  const onFinish = async (formValues) => {
    const variables = { ...formValues };
    if (editData) {
      const response = await updateUiLabel({
        variables: {
          uiLabel: {
            value: trim(formValues?.value),
            groupKey: trim(formValues?.groupKey)
          },
          id
        }
      });
      if (response?.data?.updateUiLabel) {
        form.resetFields();
        history.goBack();
      }
    } else {
      forEach(variables?.labelValues, (item) => {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        return (item.value = trim(item?.value));
      });

      const response = await createUiLabel({
        variables: {
          uiLabel: {
            ...variables,
            key: trim(formValues?.key),
            groupKey: trim(formValues?.groupKey)
          }
        }
      });
      if (response?.data?.createUiLabel) {
        form.resetFields();
        history.goBack();
      }
    }
  };

  if (dataLoading) return <LoaderComponent setHeight={10} />;
  return (
    <Form
      form={form}
      initialValues={{
        labelValues: editData?.labelValues || [{}],
        ...editData
      }}
      layout="horizontal"
      labelAlign="left"
      labelCol={{ md: { span: 5 }, sm: { span: 6 } }}
      wrapperCol={{ md: { span: 18 }, sm: { span: 18 } }}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item
            label="Key"
            name="key"
            rules={[required, { whitespace: true, message: 'Key is required' }]}
            normalize={(value) => value?.toUpperCase()}
          >
            <InputComponent placeholder="Enter Key" disabled={isEdit} />
          </Form.Item>
        </Col>
        <Col md={10} sm={24} xs={24}>
          <Form.Item
            label="Group Key"
            name="groupKey"
            rules={[
              required,
              { whitespace: true, message: 'Group Key is required' }
            ]}
          >
            <InputComponent placeholder="Enter Group Key" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="organizationIds" label="Apps" rules={[required]}>
            <SelectComponent
              disabled={isEdit}
              placeholder="Apps"
              allowClear
              showArrow
              mode="multiple"
              loading={organizationsDataLoading}
              notFoundContent={
                organizationsDataLoading ? (
                  <div className="d-flex justify-center">
                    <Spin size="small" />
                  </div>
                ) : undefined
              }
              options={organizationData}
              showSearch={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-10">
        <Col span={24}>
          <h4 className="font-21 font-weight-600 mb-16">Label Values</h4>
        </Col>
      </Row>
      {!editData && (
        <Form.List name="labelValues">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <>
                  <Row key={field?.key} gutter={16}>
                    <Col md={10} sm={24} xs={24}>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            label="Language"
                            name={[field?.name, 'language']}
                            rules={[required]}
                          >
                            <SelectComponent
                              placeholder="Language"
                              allowClear
                              showArrow
                              loading={languageLoading}
                              onSearch={debouncedLanguageHandler}
                              onClear={handleLanguageClear}
                              onPopupScroll={onLanguageScroll}
                              notFoundContent={
                                languageLoading ? (
                                  <div className="d-flex justify-center">
                                    <Spin size="small" />
                                  </div>
                                ) : undefined
                              }
                            >
                              {map(languageData, (language) => {
                                return (
                                  <Select.Option
                                    key={language?.key}
                                    value={language?.key}
                                  >
                                    {language?.value}
                                  </Select.Option>
                                );
                              })}
                            </SelectComponent>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            {...field}
                            name={[field?.name, 'value']}
                            label="Value"
                            rules={[
                              required,
                              { whitespace: true, message: 'Value is required' }
                            ]}
                          >
                            <InputComponent placeholder="Value" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={10} sm={24} xs={24}>
                      <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        disabled={fields.length < 2}
                        onClick={() => remove(field?.name)}
                      />
                      <Form.Item className="mt-20">
                        <Button onClick={() => add()} icon={<PlusOutlined />} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          )}
        </Form.List>
      )}
      {editData && (
        <Row gutter={16}>
          <Col md={10} sm={24} xs={24}>
            <Form.Item label="Language" name="language" rules={[required]}>
              <SelectComponent
                placeholder="Language"
                allowClear
                showArrow
                disabled
                loading={languageLoading}
                onSearch={debouncedLanguageHandler}
                onClear={handleLanguageClear}
                onPopupScroll={onLanguageScroll}
                notFoundContent={
                  languageLoading ? (
                    <div className="d-flex justify-center">
                      <Spin size="small" />
                    </div>
                  ) : undefined
                }
              >
                {map(languageData, (language) => {
                  return (
                    <Select.Option key={language?.key} value={language?.key}>
                      {language?.value}
                    </Select.Option>
                  );
                })}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col md={10} sm={24} xs={24}>
            <Form.Item
              name="value"
              label="Value"
              rules={[
                required,
                { whitespace: true, message: 'Value is required' }
              ]}
            >
              <InputComponent placeholder="Value" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={10} sm={24} xs={24}>
          <div className="d-flex">
            <Form.Item className="mr-5">
              <Button
                loading={createLoading || updateLoading}
                type="primary"
                htmlType="submit"
              >
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => history.goBack()}>Back</Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default UiLabelForm;
