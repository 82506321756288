import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Button, message } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ForgotPassword from './modules/auth/ForgotPassword';
import { GET_PROFILE } from './modules/auth/graphql/Queries';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ResetPassword from './modules/auth/ResetPassword';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const path = history?.location?.pathname;
  const idToken = getToken();
  const [getUserProfile, { loading }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      initializeAuth();
    },
    onError: () => {
      history.push(ROUTES.LOGOUT);
    }
  });
  useEffect(() => {
    if (path === ROUTES.LOGOUT || idToken) {
      getUserProfile();
    }
    // eslint-disable-next-line no-undef
    window.navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.waiting) {
          // Service worker is in the "skip waiting" phase
          message.info({
            content: (
              <>
                New version available! Click Reload to get the latest version.
                <Button
                  className="ml-1 mb-0"
                  type="link"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    registration &&
                      registration.waiting &&
                      registration.waiting.postMessage &&
                      registration.waiting.postMessage({
                        type: 'SKIP_WAITING'
                      });
                    // eslint-disable-next-line no-undef
                    window.location.reload();
                  }}
                >
                  Reload
                </Button>
              </>
            ),
            duration: 0
          });
        }
      });
    });
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line no-undef
  window.onload = () => {
    // eslint-disable-next-line no-console
    console.log('reload');
    // eslint-disable-next-line no-undef
    window.navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.waiting) {
          // eslint-disable-next-line no-unused-expressions
          registration &&
            registration.waiting &&
            registration.waiting.postMessage &&
            registration.waiting.postMessage({
              type: 'SKIP_WAITING'
            });
        }
      });
    });
  };

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  if (loading) return <LoaderComponent />;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <PublicRoute
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
          <PublicRoute exact path={ROUTES.RESET} component={ResetPassword} />
          <PrivateRoute exact path={ROUTES.LOGOUT} component={Logout} />
          <PrivateRoute path={ROUTES.MAIN} component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
