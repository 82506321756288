import { useMutation } from '@apollo/client';
import { Col, Form, Modal, Row } from 'antd';
import { trim } from 'lodash';
import React, { useEffect } from 'react';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import { CREATE_LANGUAGE_V2, UPDATE_LANGUAGE } from '../graphql/Mutation';

const { required } = formValidatorRules;
const AddEditLanguageModal = ({
  showModal,
  setShowModal,
  isEdit,
  editData,
  refetch
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [createLanguage, { loading: createLoading }] = useMutation(
    CREATE_LANGUAGE_V2,
    {
      onError() {}
    }
  );

  const [updateLanguage, { loading: updateLoading }] = useMutation(
    UPDATE_LANGUAGE,
    {
      onError() {}
    }
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const onFinish = async (formValues) => {
    const variables = { ...formValues };
    if (editData) {
      const response = await updateLanguage({
        variables: {
          data: {
            key: trim(variables?.key),
            value: trim(variables?.value)
          },
          where: editData?.id
        }
      });
      if (response?.data?.updateLanguage) {
        form.resetFields();
        setShowModal(false);
        refetch();
      }
    } else {
      const response = await createLanguage({
        variables: {
          data: {
            key: trim(variables?.key),
            value: trim(variables?.value),
            notificationLabels: {
              missedCall: {
                title: variables?.missedTitle,
                description: variables?.missedDescription
              },
              newCall: {
                title: variables?.newTitle,
                description: variables?.newDescription
              }
            }
          }
        }
      });
      if (response?.data?.createLanguageV2) {
        form.resetFields();
        setShowModal(false);
        refetch();
      }
    }
  };

  return (
    <div>
      <Modal
        title={`${isEdit ? 'Edit Language' : 'Add Language'}`}
        visible={showModal}
        onCancel={handleCancel}
        okText={`${isEdit ? 'Save' : 'Add'}`}
        onOk={() => {
          form.submit();
        }}
        centered
        className="common-modal"
        confirmLoading={createLoading || updateLoading}
      >
        <Form
          form={form}
          initialValues={editData}
          labelCol={{
            span: 6
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Key"
            name="key"
            rules={[required, { whitespace: true, message: 'Key is required' }]}
            normalize={(value) => value?.toUpperCase()}
          >
            <InputComponent placeholder="Key" disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[
              required,
              { whitespace: true, message: 'Value is required' }
            ]}
            normalize={(value) =>
              value.charAt(0).toUpperCase() + value.slice(1)
            }
          >
            <InputComponent placeholder="Value" />
          </Form.Item>
          {!isEdit && (
            <>
              <Row className="mt-10">
                <Col span={24}>
                  <h4 className="mb-16">Notification Values:</h4>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col span={4} />
                <Col>
                  <h5 className="mb-16">Missed Call</h5>
                </Col>
              </Row>
              <Form.Item
                label="Title"
                name="missedTitle"
                rules={[
                  required,
                  { whitespace: true, message: 'Title is required' }
                ]}
              >
                <InputComponent placeholder="Title" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="missedDescription"
                rules={[
                  required,
                  { whitespace: true, message: 'Description is required' }
                ]}
                normalize={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              >
                <InputComponent
                  placeholder="Description"
                  type="textArea"
                  rows={3}
                />
              </Form.Item>
              <Row className="d-flex">
                <Col span={4} />
                <Col>
                  <h5 className="mb-16">New Call</h5>
                </Col>
              </Row>
              <Form.Item
                label="Title"
                name="newTitle"
                rules={[
                  required,
                  { whitespace: true, message: 'Title is required' }
                ]}
              >
                <InputComponent placeholder="Title" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="newDescription"
                rules={[
                  required,
                  { whitespace: true, message: 'Description is required' }
                ]}
                normalize={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              >
                <InputComponent
                  placeholder="Description"
                  type="textArea"
                  rows={3}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AddEditLanguageModal;
