import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useEffect, useState } from 'react';
import { DEFAULT_LANGUAGES } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import { DELETE_LANGUAGE } from '../graphql/Mutation';
import { GET_LANGUAGES } from '../graphql/Queries';
import AddEditLanguageModal from './AddEditLanguageModal';

const LanguageList = () => {
  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialLanguageFilter = {
    skip: 0,
    limit: 10
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [languageFilter, setLanguageFilter] = useState(initialLanguageFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [fetchLanguages, { data, loading, refetch }] = useLazyQuery(
    GET_LANGUAGES,
    {
      fetchPolicy: 'network-only',
      variables: { where: languageFilter },
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.abConstants?.total
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  const [deleteLanguage, { loading: removing }] = useMutation(DELETE_LANGUAGE, {
    onError() {}
  });

  useEffect(() => {
    fetchLanguages({
      variables: {
        where: languageFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setLanguageFilter({
      ...languageFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setLanguageFilter({
        ...languageFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: `${sorter?.field}_${
          sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }`
      });
      fetchLanguages({
        variables: {
          where: {
            ...languageFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: `${sorter?.field}_${
              sorter?.order === 'ascend' ? 'ASC' : 'DESC'
            }`
          }
        }
      });
    } else {
      setLanguageFilter({
        ...languageFilter,
        skip,
        limit: pagination.pageSize
      });
      fetchLanguages({
        variables: {
          where: {
            ...languageFilter,
            skip,
            limit: pagination.pageSize
          }
        }
      });
    }
  };

  const handleDelete = (id) => {
    confirm({
      closable: true,
      centered: true,
      icon: <ExclamationCircleOutlined className="danger-svg" />,
      title: 'Are you Sure?',
      content: 'Do you want to delete this Language?',
      okType: 'danger',
      okText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      okButtonProps: { removing },
      onOk: async () => {
        return deleteLanguage({
          variables: {
            where: id
          }
        })
          .then(() => {
            refetch();
          })
          .catch((err) => err);
      }
    });
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      width: '300px',
      key: 'key'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '700px',
      sorter: true,
      sortOrder: sortedInfo?.field === 'value' && sortedInfo?.order,
      key: 'value'
    },
    {
      title: 'Action',
      width: '500px',
      key: 'actions',
      render: (record) => {
        return (
          <div className="d-flex">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setIsEdit(true);
                setShowModal(true);
                setEditData(record);
              }}
            />
            {!DEFAULT_LANGUAGES?.includes(record?.key) && (
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleDelete(record?.id);
                }}
              />
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <AddEditLanguageModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        editData={editData}
        setEditData={setEditData}
        refetch={refetch}
      />
      <Portal portalId="language-add">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setShowModal(true);
            setIsEdit(false);
            setEditData('');
          }}
        >
          Add Language
        </Button>
      </Portal>
      <CommonTable
        loading={loading}
        columns={columns}
        data={data?.abConstants?.data || []}
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default LanguageList;
