import {
  BarChartOutlined,
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  TeamOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Avatar, Menu, Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { MODULES, ROUTES } from '../../../common/constants';
import NotificationModal from '../../../modules/notification/pages/NotificationModal';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();

  const onMenuSelect = (e) => {
    if (e?.key === ROUTES.NOTIFICATION) {
      setShowModal(true);
    } else {
      history.push(e?.key);
    }
    setVisible(false);
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const { firstName = '', lastName = '' } = getCurrentUser() || {};
  const userMenuOptions = (
    <Menu
      selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
      onSelect={onMenuSelect}
      className="user-menu"
    >
      <Menu.Item key={ROUTES.COMPANIES} icon={<BarChartOutlined />}>
        {MODULES.COMPANIES}
      </Menu.Item>
      <Menu.Item key={ROUTES.CHAPLAINS} icon={<TeamOutlined />}>
        {MODULES.CHAPLAINS}
      </Menu.Item>
      <Menu.Item key={ROUTES.NOTIFICATION} icon={<BellOutlined />}>
        {MODULES.NOTIFICATION}
      </Menu.Item>
      <Menu.Item key={ROUTES.CHANGEPASSWORD} icon={<UnlockOutlined />}>
        {MODULES.CHANGEPASSWORD}
      </Menu.Item>
      <Menu.Item key={ROUTES.LOGOUT} icon={<LogoutOutlined />}>
        {MODULES.LOGOUT}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="gx-avatar-row">
      <Popover
        className="d-flex flex-row align-center"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Avatar className="gx-size-35 gx-pointer mr-5" alt="Avatar" />

        <span className="gx-avatar-name d-flex flex-row align-center">
          {`${firstName} ${lastName}`}
          <DownOutlined className=" gx-fs-xxs ml-2" />
        </span>
      </Popover>
      {showModal && (
        <NotificationModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default UserProfile;
