import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UiLabelForm from '../components/UiLabelForm';
import { GET_UILABELS } from '../graphql/Queries';

const UiLabelEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const { loading: dataLoading, data } = useQuery(GET_UILABELS, {
    variables: { filter: { where: { _id: id } } },
    fetchPolicy: 'network-only',
    onError() {}
  });
  return (
    <Card>
      <h3 className="font-24 font-weight-600">
        <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
        <span>Edit UiLabel</span>
      </h3>
      <hr />
      <UiLabelForm
        isEdit
        editData={data?.uilabels?.data?.[0] || []}
        dataLoading={dataLoading}
      />
    </Card>
  );
};

export default UiLabelEdit;
