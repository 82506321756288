import { Card, Col, Row } from 'antd';
import { forEach, map, trim } from 'lodash';
import moment from 'moment';
import React from 'react';

const ResourceComment = ({ data }) => {
  const comments = [];
  forEach(data, (item) => trim(item.comment) && comments.push(item));

  return (
    <Card>
      <h2>Comments</h2>
      {map(comments, (commentData) => {
        const { name, email, updatedAt, comment } = commentData;
        return (
          <div key={commentData?.id}>
            <Row className="mb-2 mt-5">
              {(name || email) && (
                <Col>
                  {name && <span>{name}</span>}{' '}
                  {email && <span className="mr-5">( {email} )</span>}
                </Col>
              )}
              <Col>
                {(name || email) && <span className="mr-5"> · </span>}
                <span>{moment(updatedAt).fromNow()}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>{comment}</h3>
              </Col>
            </Row>
          </div>
        );
      })}
    </Card>
  );
};

export default ResourceComment;
