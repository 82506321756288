import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { COMPANIES } from '../graphql/Queries';

const defaultPageSize = 10;

const CompanyList = () => {
  const initialCompanyFilter = {
    skip: 0,
    limit: defaultPageSize,
    sortBy: 'createdAt_DESC'
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [companyFilter, setCompanyFilter] = useState(initialCompanyFilter);

  const [fetchCompanyData, { loading, data }] = useLazyQuery(COMPANIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: defaultPageSize,
        total: res?.companies?.total
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchCompanyData({ variables: { data: companyFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setCompanyFilter({
      ...companyFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCompanyFilter({
        ...companyFilter,
        sortBy:
          sorter?.order === 'ascend'
            ? `${sorter?.field}_ASC`
            : `${sorter?.field}_DESC`
      });
      fetchCompanyData({
        variables: {
          data: {
            ...companyFilter,
            skip,
            limit: pagination.pageSize,
            sortBy:
              sorter?.order === 'ascend'
                ? `${sorter?.field}_ASC`
                : `${sorter?.field}_DESC`
          }
        }
      });
    } else {
      setCompanyFilter({
        ...companyFilter,
        sortBy: 'createdAt_DESC'
      });
      fetchCompanyData({
        variables: {
          data: {
            ...companyFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const onSearchChange = async (value) => {
    const searchValue = value.trim(' ');
    setCompanyFilter({ ...companyFilter, skip: 0, search: searchValue });
    fetchCompanyData({
      variables: { data: { ...companyFilter, skip: 0, search: searchValue } }
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',

      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'LOCATION ID',
      dataIndex: 'locationId',
      key: 'locationId',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'locationId' && sortedInfo?.order
    }
  ];

  return (
    <>
      <Portal portalId="company-search-wrapper">
        <div className="d-flex">
          <SearchComponent
            id="search-container-id"
            name="Company"
            getData={onSearchChange}
          />
        </div>
      </Portal>
      <CommonTable
        loadingData={loading}
        columns={columns}
        data={data?.companies?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </>
  );
};

export default withRouter(CompanyList);
