import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import OrganizationList from '../components/OrganizationList';

function Organizations() {
  return (
    <Card>
      <div className="d-flex align-center justify-between">
        <h1>Newsletters</h1>
      </div>
      <hr />
      <OrganizationList />
    </Card>
  );
}

export default withRouter(Organizations);
