import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import ChangePassword from '../modules/auth/ChangePassword';
import ChaplainWrapper from '../modules/chaplains';
import CompanyWrapper from '../modules/companies';
import DashboardWrapper from '../modules/Dashboard';
import LanguageWrapper from '../modules/language';
import OrganizationWrapper from '../modules/organizations';
import ResourceWrapper from '../modules/resources';
import UiLabelWrapper from '../modules/uilabels';
import WebinarWrapper from '../modules/webinars';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES.MAIN}
          render={() => <Redirect to={ROUTES.DASHBOARD} />}
        />
        <Route exact path={ROUTES.DASHBOARD} component={DashboardWrapper} />
        <Route path={ROUTES.WEBINARS} component={WebinarWrapper} />
        <Route path={ROUTES.RESOURCES} component={ResourceWrapper} />
        <Route path={ROUTES.NEWSLETTERS} component={OrganizationWrapper} />
        <Route path={ROUTES.COMPANIES} component={CompanyWrapper} />
        <Route path={ROUTES.CHAPLAINS} component={ChaplainWrapper} />
        <Route exact path={ROUTES.CHANGEPASSWORD} component={ChangePassword} />
        <Route path={ROUTES.LANGUAGE} component={LanguageWrapper} />
        <Route path={ROUTES.UILABELS} component={UiLabelWrapper} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
