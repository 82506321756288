import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SEND_NOTIFICATION = gql`
  mutation sendNotification($data: NotificationData!) {
    sendNotification(data: $data) {
      status
      message
    }
  }
`;
