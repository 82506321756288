import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import { ORGANIZATIONS } from '../../resources/graphql/Queries';

const OrganizationList = (props) => {
  const { history } = props;
  const initialFilter = {
    skip: 0,
    limit: 10,
    sortBy: 'createdAt_DESC'
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [organizationFilter, setOrganizationFilter] = useState(initialFilter);
  const [fetchOrganizations, { data, loading }] = useLazyQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      data: organizationFilter
    }
  });
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setOrganizationFilter({
      ...organizationFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setOrganizationFilter({
        ...organizationFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: `${sorter?.field}_${
          sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }`
      });
      fetchOrganizations({
        variables: {
          data: {
            ...organizationFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: `${sorter?.field}_${
              sorter?.order === 'ascend' ? 'ASC' : 'DESC'
            }`
          }
        }
      });
    } else {
      setOrganizationFilter({
        ...organizationFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: 'createdAt_DESC'
      });
      fetchOrganizations({
        variables: {
          data: {
            ...organizationFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'NEWSLETTER',
      dataIndex: 'id',
      render: (id) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              type="link"
              id={`organization-action-edit-btn-${id}`}
              onClick={() => history.push(`${ROUTES.NEWSLETTERS_EDIT}/${id}`)}
            >
              Manage Newsletter
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <CommonTable
        columns={columns}
        data={data?.organizations?.data || []}
        rowKey={(obj) => obj.id}
        paginationConfig={paginationProp}
        loadingData={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default withRouter(OrganizationList);
