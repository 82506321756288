import { gql } from '@apollo/client';

export const CREATE_LANGUAGE = gql`
  mutation createLanguage($data: createLanguageInput!) {
    createLanguage(data: $data) {
      message
      data {
        key
        value
        language
        groupKey
      }
    }
  }
`;

export const CREATE_LANGUAGE_V2 = gql`
  mutation createLanguageV2($data: createLanguageInputV2!) {
    createLanguageV2(data: $data) {
      message
      data {
        key
        value
        language
        groupKey
        id
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($where: ID!, $data: createLanguageInput!) {
    updateLanguage(where: $where, data: $data) {
      message
      data {
        key
        value
        language
        groupKey
      }
    }
  }
`;

export const DELETE_LANGUAGE = gql`
  mutation deleteLanguage($where: ID!) {
    deleteLanguage(where: $where) {
      status
      message
    }
  }
`;
