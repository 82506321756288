import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Language from './Language';

const LanguageWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.LANGUAGE} component={Language} />
    </Switch>
  );
};

export default LanguageWrapper;
