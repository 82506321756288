import { Card } from 'antd';
import React from 'react';
import LanguageList from './components/LanguageList';

const Language = () => {
  return (
    <Card>
      <div className="d-flex align-center justify-between">
        <h1>Language</h1>
        <div id="language-add" />
      </div>
      <hr />
      <LanguageList />
    </Card>
  );
};

export default Language;
