import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './chaplains.less';
import Chaplains from './pages/Chaplains';

const ChaplainWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.CHAPLAINS} component={Chaplains} />
    </Switch>
  );
};

export default ChaplainWrapper;
