/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET: '/reset',
  VERIFY: '/verify',
  WEBINARS: '/webinars',
  WEBINARS_EDIT: '/webinars/edit',
  RESOURCES: '/resources',
  RESOURCES_ADD: '/resources/add',
  RESOURCES_EDIT: '/resources/edit',
  NEWSLETTERS: '/newsletters',
  NEWSLETTERS_EDIT: '/newsletters/edit',
  COMPANIES: '/companies',
  CHAPLAINS: '/chaplains',
  DASHBOARD: '/dashboard',
  CHANGEPASSWORD: '/changepassword',
  NOTIFICATION: '/notification',
  LANGUAGE: '/language',
  UILABELS: '/uilabels'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  WEBINARS: 'Webinars',
  RESOURCES: 'Resources',
  NEWSLETTERS: 'Newsletters',
  COMPANIES: 'Companies',
  CHAPLAINS: 'Chaplains',
  CHANGEPASSWORD: 'Change Password',
  NOTIFICATION: 'Notifications',
  LOGOUT: 'Sign Out',
  LANGUAGE: 'Language',
  UILABELS: 'UiLabels'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const WEBINARCODE = 'WEBINARCODE';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

export const LANGUAGES = {
  French: 'FR',
  Spanish: 'ES',
  English: 'EN-US'
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const SYNC_URL = `https://api.getgo.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_GOTO_WEBINAR_CLIENT_ID}&response_type=code&redirect_uri=`;

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg', 'svg+xml'];

export const DEFAULT_LANGUAGES = ['ES', 'FR', 'EN-US'];

export const SKIP_RECORD = 10;
