import { useLazyQuery } from '@apollo/client';
import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import { debounce, map, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultDateFormat, SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';
import { COMPANIES, LOCATIONS, ORGANIZATIONS } from '../graphql/Queries';

const { required } = formValidatorRules;
const searchLimit = 50;

const WebinarForm = (props) => {
  const {
    form,
    webinarData,
    onWebinarSubmitFinish,
    history,
    updateWebinarLoading
  } = props;

  const [organizations, setOrganizations] = useState(
    webinarData?.organizations?.map((organization) => ({
      label: organization?.name,
      value: organization?.id
    })) || []
  );
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState(
    webinarData?.locations?.map((location) => ({
      label: location?.id,
      value: location?.id
    })) || []
  );

  const initialLocationFilter = {
    skip: 0,
    limit: 50
  };

  const initialCompanyFilter = {
    skip: 0,
    limit: 50
  };
  const [locationSearchFlag, setLocationSearchFlag] = useState(false);
  const [locationSearch, setLocationSearch] = useState('');
  const [isLocationEnd, setIsLocationEnd] = useState(false);
  const [locationDebounceCall, setLocationDebounceCall] = useState(0);
  const [locationLoading, setLocationLoading] = useState(false);
  const [companySearchFlag, setCompanySearchFlag] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [isCompanyEnd, setIsCompanyEnd] = useState(false);
  const [companyDebounceCall, setCompanyDebounceCall] = useState(0);
  const [companyLoading, setCompanyLoading] = useState(false);
  const debounceJob = useRef();
  let locationDebounceJob;
  let companyDebounceJob;

  const [
    fetchOrganizationsData,
    { loading: organizationsDataLoading }
  ] = useLazyQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(data) {
      if (data?.organizations?.data?.length) {
        const tempOrganizations = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data?.organizations?.data?.length; i++) {
          const organization = data?.organizations?.data?.[i];
          tempOrganizations.push({
            label: organization?.name,
            value: organization?.id
          });
        }
        const selectedOrganizations =
          webinarData?.organizations?.map((organization) => ({
            label: organization?.name,
            value: organization?.id
          })) || [];
        setOrganizations(
          uniqBy([...selectedOrganizations, ...tempOrganizations], 'value')
        );
      } else {
        setOrganizations([]);
      }
    }
  });

  const [fetchCompaniesData] = useLazyQuery(COMPANIES, {
    fetchPolicy: 'network-only',
    onError() {
      setCompanyLoading(false);
    },
    onCompleted(res) {
      if (res?.companies?.data?.length < SKIP_RECORD) {
        setIsCompanyEnd(true);
      }

      if (companySearchFlag) {
        setCompanies([...res?.companies?.data]);
      } else if (webinarData?.companies) {
        const selectedCompanies = [
          ...webinarData?.companies,
          ...res?.companies?.data
        ];

        setCompanies(uniqBy([...companies, ...selectedCompanies], 'id'));
      } else {
        setCompanies([...companies, ...res?.companies?.data]);
      }
      setCompanyLoading(false);
    }
  });

  const [fetchLocationsData] = useLazyQuery(LOCATIONS, {
    fetchPolicy: 'network-only',
    onError() {
      setLocationLoading(false);
    },
    onCompleted(res) {
      const tempLocations = [];
      map(res?.companyLocations?.data, (location) => {
        tempLocations?.push(location?.locationId);
      });
      if (res?.companyLocations?.data?.length < SKIP_RECORD) {
        setIsLocationEnd(true);
      }
      if (locationSearchFlag) {
        setLocations([...tempLocations]);
      } else if (webinarData?.locationIds) {
        setLocations(uniq([...webinarData?.locationIds, ...tempLocations]));
      } else {
        setLocations([...locations, ...res?.companyLocations?.data]);
      }
      setLocationLoading(false);
    }
  });

  useEffect(() => {
    fetchOrganizationsData({
      variables: { data: { skip: 0, limit: searchLimit } }
    });
    fetchCompaniesData({
      variables: { data: initialCompanyFilter }
    });
    fetchLocationsData({
      variables: { data: initialLocationFilter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function debounceFetchOrganizations(search) {
    if (debounceJob?.current) {
      debounceJob?.current?.cancel();
    }
    debounceJob.current = debounce(() => {
      fetchOrganizationsData({
        variables: { data: { skip: 0, limit: searchLimit, search } }
      });
    }, 500);

    debounceJob?.current();
  }

  const handleLocationChange = (value) => {
    setLocationSearch(value);
    if (value) {
      setLocationSearchFlag(true);
      setLocationLoading(true);
      fetchLocationsData({
        variables: {
          data: {
            ...initialLocationFilter,
            search: value
          }
        }
      });
    } else {
      setLocationLoading(false);
      setLocationSearchFlag(false);
      fetchLocationsData({
        variables: {
          data: {
            ...initialLocationFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLocationHandler = useCallback(
    debounce(handleLocationChange, 500),
    []
  );

  const handleLocationBlur = () => {
    setLocationSearch('');
    setIsLocationEnd(false);
    setLocationDebounceCall(0);
    setLocationSearchFlag(false);
  };

  const handleLocationClear = () => {
    fetchLocationsData({
      variables: { data: initialLocationFilter }
    });
  };

  const onLocationScroll = (event) => {
    setLocationSearchFlag(false);
    if (locationDebounceJob) {
      locationDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    locationDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isLocationEnd) {
        setLocationLoading(true);
        setLocationDebounceCall((prevState) => prevState + 1);
        fetchLocationsData({
          variables: {
            data: {
              ...initialLocationFilter,
              skip: (locationDebounceCall + 1) * SKIP_RECORD,
              search: locationSearch
            }
          }
        });
      }
    }, 500);
    locationDebounceJob();
  };

  const handleCompanyBlur = () => {
    setCompanySearch('');
    setIsCompanyEnd(false);
    setCompanyDebounceCall(0);
    setCompanySearchFlag(false);
  };

  const handleCompanyClear = () => {
    fetchCompaniesData({
      variables: { data: initialCompanyFilter }
    });
  };

  const onCompanyScroll = (event) => {
    setCompanySearchFlag(false);
    if (companyDebounceJob) {
      companyDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    companyDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isCompanyEnd) {
        setCompanyLoading(true);
        setCompanyDebounceCall((prevState) => prevState + 1);
        fetchCompaniesData({
          variables: {
            data: {
              ...initialCompanyFilter,
              skip: (companyDebounceCall + 1) * SKIP_RECORD,
              search: companySearch
            }
          }
        });
      }
    }, 500);
    companyDebounceJob();
  };

  const handleCompanySearch = (value) => {
    setCompanySearch(value);
    if (value) {
      setCompanySearchFlag(true);
      setCompanyLoading(true);
      fetchCompaniesData({
        variables: {
          data: {
            ...initialCompanyFilter,
            search: value
          }
        }
      });
    } else {
      setCompanyLoading(false);
      setCompanySearchFlag(false);
      fetchCompaniesData({
        variables: {
          data: {
            ...initialCompanyFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCompanyHandler = useCallback(
    debounce(handleCompanySearch, 500),
    []
  );

  const handleCompanyChange = (value, data) => {
    const locationId = data?.['data-locationId'];
    const getOldFormValue = form?.getFieldValue('locationIds');
    if (getOldFormValue?.includes(locationId)) {
      form?.setFieldsValue({
        locationIds: getOldFormValue
      });
    } else {
      form?.setFieldsValue({
        locationIds: [...getOldFormValue, locationId]
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        ...webinarData,
        startDate: webinarData?.startDate
          ? moment(webinarData?.startDate)
          : undefined,
        endDate: webinarData?.endDate ? moment(webinarData?.endDate) : undefined
      }}
      layout="horizontal"
      className="mt-25"
      labelAlign="left"
      labelCol={{ md: { span: 5 }, sm: { span: 6 } }}
      wrapperCol={{ md: { span: 18 }, sm: { span: 18 } }}
      onFinish={onWebinarSubmitFinish}
    >
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="title" label="Title" rules={[required]}>
            <InputComponent placeholder="title" />
          </Form.Item>
        </Col>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="description" label="Description">
            <InputComponent placeholder="Description" type="textArea" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="startDate" label="Start Date" rules={[required]}>
            <DatePicker disabled format={`${defaultDateFormat} - HH:mm a`} />
          </Form.Item>
        </Col>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="endDate" label="End Date" rules={[required]}>
            <DatePicker disabled format={`${defaultDateFormat} - HH:mm a`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="organizationIds" label="Apps">
            <SelectComponent
              placeholder="Apps"
              allowClear
              mode="multiple"
              filterOption={false}
              showArrow
              loading={organizationsDataLoading}
              onSearch={(search) => debounceFetchOrganizations(search)}
              notFoundContent={
                organizationsDataLoading ? (
                  <div className="d-flex justify-center">
                    <Spin size="small" />
                  </div>
                ) : undefined
              }
              options={organizations}
            />
          </Form.Item>
        </Col>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="companyIds" label="Companies">
            <SelectComponent
              placeholder="Companies"
              allowClear
              mode="multiple"
              filterOption={false}
              showArrow
              loading={companyLoading}
              onBlur={handleCompanyBlur}
              onSearch={debouncedCompanyHandler}
              onClear={handleCompanyClear}
              onPopupScroll={onCompanyScroll}
              onSelect={handleCompanyChange}
              notFoundContent={
                companyLoading ? (
                  <div className="d-flex justify-center">
                    <Spin size="small" />
                  </div>
                ) : undefined
              }
            >
              {map(companies, (company) => (
                <Select.Option
                  key={company?.id}
                  value={company?.id}
                  data-locationId={company?.locationId}
                >
                  {company?.name}
                </Select.Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="locationIds" label="Locations">
            <SelectComponent
              placeholder="Locations"
              allowClear
              mode="multiple"
              filterOption={false}
              showArrow
              loading={locationLoading}
              onBlur={handleLocationBlur}
              onSearch={debouncedLocationHandler}
              onClear={handleLocationClear}
              onPopupScroll={onLocationScroll}
              notFoundContent={
                locationLoading ? (
                  <div className="d-flex justify-center">
                    <Spin size="small" />
                  </div>
                ) : undefined
              }
            >
              {map(locations, (location) => (
                <Select.Option key={location} value={location}>
                  {location}
                </Select.Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={10} sm={24} xs={24}>
          <div className="d-flex">
            <Form.Item className="mr-5">
              <Button
                type="primary"
                loading={updateWebinarLoading}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => history.goBack()}>Back</Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(WebinarForm);
