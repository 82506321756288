import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import WebinarEdit from './pages/WebinarEdit';
import Webinars from './pages/Webinars';
import './webinars.less';

const WebinarWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES.WEBINARS_EDIT}/:id`}
        component={WebinarEdit}
      />
      <Route exact path={ROUTES.WEBINARS} component={Webinars} />
    </Switch>
  );
};

export default WebinarWrapper;
