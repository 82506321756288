import { DeleteOutlined, EyeOutlined, LeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Modal } from 'antd';
import {
  cloneDeep,
  includes,
  keys,
  map,
  pickBy,
  pullAt,
  remove,
  replace
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { fileUpload } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import { LANGUAGES, ORGANIZATIONS } from '../../resources/graphql/Queries';
import OrganizationForm from '../components/OrganizationForm';
import { GET_SIGNED_URL, UPDATE_NEWSLETTER } from '../graphql/Mutations';

const { confirm } = Modal;

const OrganizationEdit = (props) => {
  const { history } = props;
  const { id } = useParams();
  const [fileUrl, setFileUrl] = useState();
  const [selected, setSelected] = useState();
  const [fileObj, setFileObj] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [newsLetters, setNewsLetters] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languageKeys, setLanguageKeys] = useState({});
  const [updateNewsLetter, { loading: updating }] = useMutation(
    UPDATE_NEWSLETTER
  );
  const [fetchSignedUrl] = useMutation(GET_SIGNED_URL);
  const { data, refetch } = useQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        skip: 0
      }
    }
  });

  const [fetchLanguages] = useLazyQuery(LANGUAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: {}
    },
    onCompleted(res) {
      setLanguages(res?.abConstants?.data);
      const fetchKeys = map(res?.abConstants?.data, (language) => {
        return { [language?.value]: language?.key };
      });
      const convertedObj = Object.assign({}, ...fetchKeys);
      setLanguageKeys(convertedObj);
    },
    onError() {}
  });

  useEffect(() => {
    map(data?.organizations?.data, (organization) => {
      if (id === organization?.id) {
        setSelected(organization);
        const parsedData = JSON.parse(
          JSON.stringify(organization?.newsLetters),
          (key, value) => {
            if (key !== '__typename' && key !== 'id') {
              return value;
            }
          }
        );
        setNewsLetters([...parsedData]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    fetchLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSelectedLanguageIfAvailable = (language) => {
    const copyNews = cloneDeep(newsLetters);
    const newsletterIndexes = keys(
      pickBy(newsLetters, (item) => item?.language === language)
    );

    if (!includes(newsletterIndexes, '-1')) {
      pullAt(copyNews, newsletterIndexes);
    }
    return copyNews;
  };

  const onOrganizationSubmitFinish = async (values) => {
    setLoading(true);

    const imageName = replace(
      fileObj?.name,
      new RegExp('[&/\\#,+()$~%\'":*?<>{}]', 'g'),
      '_'
    );
    const response = await fetchSignedUrl({
      variables: {
        data: {
          key: imageName,
          contentType: fileObj?.type
        }
      }
    });

    if (response) {
      const signedImageUrl =
        response?.data?.getSignedPutUrlNewsLetter?.signedUrl;

      fileUpload(signedImageUrl, fileObj).then(async () => {
        const newsLetter = {
          language: languageKeys[values?.language],
          languageValue: values?.language,
          url: response?.data?.getSignedPutUrlNewsLetter?.getUrl
        };
        const resp = removeSelectedLanguageIfAvailable(
          languageKeys[values?.language]
        );
        setNewsLetters([...newsLetters, newsLetter]);
        try {
          const update = await updateNewsLetter({
            variables: {
              data: [...resp, newsLetter],
              where: {
                organizationId: id
              }
            }
          });
          if (update?.data?.updateNewsLetter) {
            form.resetFields();
            setFileUrl();
            setFileObj();
            setLoading(false);
            refetch();
          }
        } catch (error) {
          setLoading(false);
          return error;
        }
      });
    }
  };

  const handleLanguageDelete = (record) => {
    const { languageValue = '' } = record;
    confirm({
      centered: true,
      title: `Do you want to delete file of ${languageValue}?`,
      icon: <DeleteOutlined />,
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: { updating },
      onOk: async () => {
        const newsLetter = cloneDeep(newsLetters);
        remove(newsLetter, function (n) {
          return n?.languageValue === languageValue;
        });
        const res = await updateNewsLetter({
          variables: {
            data: newsLetter,
            where: {
              organizationId: id
            }
          }
        });
        if (res?.data?.updateNewsLetter) {
          form.resetFields();
          refetch();
        }
      }
    });
  };

  const columns = [
    {
      title: 'LANGUAGE',
      dataIndex: 'languageValue',
      key: 'language'
    },
    {
      title: 'ACTIONS',
      render: (value, record) => {
        return (
          <div className="d-flex">
            <a
              download
              target="_blank"
              rel="noopener noreferrer"
              href={record?.url}
            >
              <Button type="link" className="mr-5" icon={<EyeOutlined />} />
            </a>
            <Button
              danger
              type="link"
              icon={<DeleteOutlined />}
              onClick={(e) => handleLanguageDelete(record, e)}
              disabled={selected?.newsLetters?.length < 2}
            />
          </div>
        );
      }
    }
  ];

  return (
    <Card>
      <h3>
        <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
        <span>Manage Newsletters</span>
      </h3>
      <hr />
      <OrganizationForm
        form={form}
        onOrganizationSubmitFinish={onOrganizationSubmitFinish}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        setFileObj={setFileObj}
        fileObj={fileObj}
        loading={loading}
        languages={languages}
      />
      <CommonTable
        columns={columns}
        data={selected?.newsLetters || []}
        rowKey={(obj) => obj?.language}
      />
    </Card>
  );
};

export default withRouter(OrganizationEdit);
