import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_WEBINAR = gql`
  mutation deleteWebinar($where: ID!) {
    deleteWebinar(where: $where) {
      status
      message
    }
  }
`;

export const UPDATE_WEBINAR = gql`
  mutation updateWebinar($where: ID!, $data: UpdateWebinarInput) {
    updateWebinar(where: $where, data: $data) {
      id
      title
    }
  }
`;
