import { DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, message, Modal, Radio, Space } from 'antd';
import axios from 'axios';
import { clone, get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  defaultDateFormat,
  ROUTES,
  SYNC_URL,
  WEBINARCODE
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { DELETE_WEBINAR } from '../graphql/Mutations';
import { ORGANIZATIONS, WEBINARS } from '../graphql/Queries';

const { confirm } = Modal;
const defaultPageSize = 10;
const organizationSearchLimit = 50;

const WebinarList = () => {
  const history = useHistory();
  const location = useLocation();
  const { getToken } = useContext(AppContext);
  const token = getToken();

  const initialWebinarFilter = {
    skip: 0,
    limit: defaultPageSize,
    sortBy: 'createdAt_DESC'
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [webinarFilter, setWebinarFilter] = useState(initialWebinarFilter);
  const [appFilter, setAppFilter] = useState([]);

  const [deleteWebinarMutate, { loading: deleteWebinarLoading }] = useMutation(
    DELETE_WEBINAR,
    {
      onError() {}
    }
  );

  const { loading: organizationsDataLoading } = useQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { data: { skip: 0, limit: organizationSearchLimit } },
    onCompleted: (data) => {
      let filters = [];
      if (data?.organizations?.data?.length) {
        filters = data?.organizations?.data?.map((organization) => {
          return {
            text: organization?.name,
            value: organization?.id
          };
        });
      }
      setAppFilter([...clone(appFilter), ...filters]);
    },
    onError() {}
  });

  const [fetchWebinarData, { loading, data, refetch }] = useLazyQuery(
    WEBINARS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: defaultPageSize,
          total: res?.webinars?.total
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchWebinarData({ variables: { data: webinarFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncWebinar = (code) => {
    const url = `${process.env.REACT_APP_SERVER_REST_URL}/api/webinars/sync?code=${code}`;
    axios
      .get(url, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        if (res?.status === 200) {
          fetchWebinarData({ variables: { data: webinarFilter } });
          history.replace(ROUTES.WEBINARS);
          message.success(res?.data?.message);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('error =>', err);
      });
  };

  useEffect(() => {
    if (location?.search) {
      const params = new URLSearchParams(location?.search);
      const code = params.get('code');
      // eslint-disable-next-line no-undef
      const webinarCode = localStorage.getItem(WEBINARCODE);
      // if we found code in url and code is not same as previous code then make api call for seminar
      if (code && webinarCode !== code) {
        // set code in localStorage so user doesn't call if same code given
        // eslint-disable-next-line no-undef
        localStorage.setItem(WEBINARCODE, code);
        syncWebinar(code);
      } else {
        fetchWebinarData({ variables: { data: webinarFilter } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setWebinarFilter({
      ...webinarFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      let sortBy = 'createdAt_DESC';
      if (sorter?.field === 'organizations') {
        sortBy =
          sorter?.order === 'ascend'
            ? 'organizationIds_ASC'
            : 'organizationIds_DESC';
      } else {
        sortBy =
          sorter?.order === 'ascend'
            ? `${sorter?.field}_ASC`
            : `${sorter?.field}_DESC`;
      }
      setWebinarFilter({
        ...webinarFilter,
        sortBy
      });
      fetchWebinarData({
        variables: {
          data: {
            ...webinarFilter,
            skip,
            limit: pagination.pageSize,
            sortBy
          }
        }
      });
    } else {
      setWebinarFilter({
        ...webinarFilter,
        sortBy: 'createdAt_DESC'
      });
      fetchWebinarData({
        variables: {
          data: {
            ...webinarFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const onSearchChange = async (value) => {
    const searchValue = value.trim(' ');
    setWebinarFilter({ ...webinarFilter, skip: 0, search: searchValue });
    fetchWebinarData({
      variables: { data: { ...webinarFilter, skip: 0, search: searchValue } }
    });
  };

  const handleAppFilter = (selectedKeys, confirmFilter) => {
    confirmFilter();
    setWebinarFilter({
      ...webinarFilter,
      skip: 0,
      where: { organizationIds: [selectedKeys] }
    });
    fetchWebinarData({
      variables: {
        data: {
          ...webinarFilter,
          skip: 0,
          where: { organizationIds: [selectedKeys] }
        }
      }
    });
  };

  function handleAppFilterReset(clearFilters) {
    clearFilters();
    const tempFilter = clone(webinarFilter);
    if (tempFilter?.where) {
      delete tempFilter?.where;
    }
    setWebinarFilter({
      ...tempFilter,
      skip: 0
    });
    fetchWebinarData({
      variables: { data: { ...tempFilter, skip: 0 } }
    });
  }

  function filterData(confirmFilter) {
    confirmFilter();
    setWebinarFilter({
      ...webinarFilter,
      sortBy: 'createdAt_DESC'
    });
    fetchWebinarData({
      variables: {
        data: {
          ...webinarFilter,
          sortBy: 'createdAt_DESC'
        }
      }
    });
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm: confirmFilter,
        clearFilters
      }) => (
        <div className="d-flex flex-vertical webinar-appfilter-wrapper">
          <Radio.Group
            onChange={(e) => setSelectedKeys(e?.target?.value ?? '')}
            value={selectedKeys}
          >
            <Space direction="vertical">
              {appFilter?.map((filter) => (
                <Radio key={filter?.value} value={filter?.value}>
                  {filter?.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <div className="webinar-appfilter-btn-wrapper">
            <Button
              id={`btn-${dataIndex}-search`}
              type="primary"
              onClick={() => {
                if (selectedKeys?.length > 0) {
                  handleAppFilter(selectedKeys, confirmFilter, dataIndex);
                } else {
                  filterData(confirmFilter);
                }
              }}
              size="small"
              className="mr-5"
            >
              Ok
            </Button>
            <Button
              id={`btn-${dataIndex}-reset`}
              onClick={() => handleAppFilterReset(clearFilters, dataIndex)}
              size="small"
            >
              Reset
            </Button>
          </div>
        </div>
      )
    };
  }

  function handleWebinarDelete(record) {
    const { title, id } = record;
    confirm({
      title: `You want to delete webinar ${title}?`,
      icon: <DeleteOutlined />,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          const response = await deleteWebinarMutate({
            variables: { where: id }
          });

          if (response?.data?.deleteWebinar) {
            refetch();
          }
        } catch (error) {
          if (get(error, 'response.data.message')) {
            message.error(error.response.data.message);
          } else if (error && error.message) {
            message.error(error.message);
          } else {
            message.error('Something went wrong');
          }
        }
      }
    });
  }

  const renderCommaSeparatedList = (datalist, config) => {
    const { isObject = false, key } = config || {};

    if (!datalist || !datalist.length) {
      return '';
    }

    let commaSeparatedList = datalist.map((dataItem) => {
      if (!isObject) {
        return dataItem;
      }

      return dataItem[key];
    });

    commaSeparatedList = commaSeparatedList.join(', ');
    return <div className="comma-separated-list">{commaSeparatedList}</div>;
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      className: 'wb-word',
      width: 400,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order
    },
    {
      title: 'APPS',
      dataIndex: 'organizations',
      key: 'organizations',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'organizations' && sortedInfo?.order,
      filters: appFilter,
      filterMultiple: false,
      ...getColumnSearchProps('organizations'),
      render: (organizations) =>
        renderCommaSeparatedList(organizations, {
          isObject: true,
          key: 'name'
        }) || '--'
    },
    {
      title: 'COMPANIES',
      dataIndex: 'companies',
      key: 'companies',
      render: (companies) =>
        renderCommaSeparatedList(companies, { isObject: true, key: 'name' }) ||
        '--'
    },
    {
      title: 'LOCATION',
      dataIndex: 'locationIds',
      key: 'locationIds',
      render: (locations) => {
        return locations?.join(', ') || '--';
      }
    },
    {
      title: 'START DATE',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'startDate' && sortedInfo?.order,
      render: (date) => formatDate(date, defaultDateFormat)
    },
    {
      title: 'END DATE',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'endDate' && sortedInfo?.order,
      render: (date) => formatDate(date, defaultDateFormat)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <div className="d-flex">
            <Button
              type="link"
              id={`webinar-action-edit-btn-${id}`}
              icon={<EditOutlined />}
              onClick={() => history.push(`${ROUTES.WEBINARS_EDIT}/${id}`)}
            />
            <Button
              type="link"
              danger
              id={`webinar-action-delete-btn-${id}`}
              icon={<DeleteOutlined />}
              onClick={(e) => handleWebinarDelete(record, e)}
            />
          </div>
        );
      }
    }
  ];

  const pageUrl = encodeURIComponent(
    // eslint-disable-next-line no-undef
    window.location.origin + location?.pathname
  );

  const goToWebinarClient = () => {
    // eslint-disable-next-line no-undef
    window.location = `${SYNC_URL}${pageUrl}`;
  };

  return (
    <>
      <Portal portalId="webinar-search-wrapper">
        <div className="d-flex">
          <SearchComponent
            id="search-container-id"
            name="Webinars"
            getData={onSearchChange}
          />
          <Button
            type="primary"
            icon={<SyncOutlined />}
            className="webinar-sync-btn"
            onClick={() => goToWebinarClient()}
          >
            Sync
          </Button>
        </div>
      </Portal>
      <CommonTable
        loadingData={
          loading || deleteWebinarLoading || organizationsDataLoading
        }
        tableClassName="webinar-table"
        columns={columns}
        data={data?.webinars?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </>
  );
};

export default withRouter(WebinarList);
