import { Layout, Menu } from 'antd';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logoSymbol from '../../../assets/logo-symbol.png';
import { MODULES, ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';

const { Header } = Layout;

const AppHeader = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onMenuSelect = (e) => {
    history.push(e?.key);
  };

  return (
    <Header>
      <div className="header-navigation-wrapper">
        <div className="header-logo">
          <Link to={ROUTES.DASHBOARD}>
            <img src={logoSymbol} alt="Logo-Symbol" />
          </Link>
        </div>
        <Menu
          mode="horizontal"
          className="header-navigation"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          onSelect={onMenuSelect}
        >
          <Menu.Item key={ROUTES.WEBINARS}>{MODULES.WEBINARS}</Menu.Item>
          <Menu.Item key={ROUTES.RESOURCES}>{MODULES.RESOURCES}</Menu.Item>
          <Menu.Item key={ROUTES.NEWSLETTERS}>{MODULES.NEWSLETTERS}</Menu.Item>
          <Menu.Item key={ROUTES.LANGUAGE}>{MODULES.LANGUAGE}</Menu.Item>
          <Menu.Item key={ROUTES.UILABELS}>{MODULES.UILABELS}</Menu.Item>
        </Menu>
        <UserProfile />
      </div>
    </Header>
  );
};
export default AppHeader;
