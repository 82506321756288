import { Card } from 'antd';
import React from 'react';
import './dashboard.less';

const Dashboard = () => {
  return (
    <Card className="dashboard">
      <iframe
        title="mpc"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        src="https://datastudio.google.com/embed/reporting/397d6f53-1503-41c4-8f7d-70e2c8c008bf/page/oiNY"
      />
    </Card>
  );
};

export default Dashboard;
