import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ChaplainList from '../components/ChaplainList';

function Chaplains() {
  return (
    <Card>
      <div className="d-flex align-center justify-between">
        <h1>Chaplains</h1>
        <div id="chaplain-search-wrapper" />
      </div>
      <hr />
      <ChaplainList />
    </Card>
  );
}

export default withRouter(Chaplains);
