import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './organizations.less';
import OrganizationEdit from './pages/OrganizationEdit';
import Organizations from './pages/Organizations';

const OrganizationWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES.NEWSLETTERS_EDIT}/:id`}
        component={OrganizationEdit}
      />
      <Route exact path={ROUTES.NEWSLETTERS} component={Organizations} />
    </Switch>
  );
};

export default OrganizationWrapper;
