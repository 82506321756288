import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';
import React from 'react';
import NotificationForm from '../components/NotificationForm';
import { SEND_NOTIFICATION } from '../graphql/Mutations';

const NotificationModal = (props) => {
  const { showModal, setShowModal } = props;
  const [form] = Form.useForm();

  const [
    sendNotifiactionMutate,
    { loading: sendNotificationLoading }
  ] = useMutation(SEND_NOTIFICATION, {
    onError() {}
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onSendNotification = async (values) => {
    const { title, description, organizationIds } = values;
    const data = {
      title,
      description,
      refData: { organizationIds }
    };

    try {
      const response = await sendNotifiactionMutate({
        variables: { data }
      });
      if (response?.data?.sendNotification) {
        form.resetFields();
        setShowModal(false);
      }
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  return (
    <Modal
      title="Send Notification"
      visible={showModal}
      confirmLoading={sendNotificationLoading}
      onOk={handleAdd}
      className="dialog"
      okText="Send"
      onCancel={handleCancel}
    >
      <NotificationForm form={form} onSendNotification={onSendNotification} />
    </Modal>
  );
};

export default NotificationModal;
