import { LeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Card, Form, Spin } from 'antd';
import React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import WebinarForm from '../components/WebinarForm';
import { UPDATE_WEBINAR } from '../graphql/Mutations';
import { WEBINARS } from '../graphql/Queries';

const ResourceEdit = (props) => {
  const { history } = props;
  const { id } = useParams();
  const [form] = Form.useForm();

  const { loading: webinarDataLoading, data } = useQuery(WEBINARS, {
    variables: { data: { where: { _id: id } } },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [updateWebinarMutate, { loading: updateWebinarLoading }] = useMutation(
    UPDATE_WEBINAR,
    {
      onError() {}
    }
  );

  const onWebinarSubmitFinish = async (values) => {
    const variables = {
      where: id,
      data: {
        title: values?.title,
        description: values?.description,
        organizationIds: values?.organizationIds,
        companyIds: values?.companyIds,
        locationIds: values?.locationIds
      }
    };

    try {
      const response = await updateWebinarMutate({ variables });
      if (response?.data?.updateWebinar) {
        history.goBack();
      }
      return response;
    } catch (error) {
      return error;
    }
  };
  return (
    <Card>
      <h3>
        <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
        <span>Edit Webinars</span>
      </h3>
      <hr />
      {webinarDataLoading ? (
        <div className="d-flex justify-center align-center webinar-form-loading">
          <Spin size="large" />
        </div>
      ) : (
        <WebinarForm
          form={form}
          webinarData={data?.webinars?.data?.[0] || {}}
          onWebinarSubmitFinish={onWebinarSubmitFinish}
          updateWebinarLoading={updateWebinarLoading}
        />
      )}
    </Card>
  );
};

export default withRouter(ResourceEdit);
