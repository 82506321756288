import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Select, Spin } from 'antd';
import { debounce, isEmpty, map, trim, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import SelectComponent from '../../../components/SelectComponent';
import { GET_LANGUAGES } from '../../language/graphql/Queries';
import { ORGANIZATIONS } from '../../resources/graphql/Queries';
import { GET_UILABELS } from '../graphql/Queries';

const UiLabelsList = () => {
  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialUiLabelFilter = {
    skip: 0,
    limit: 10,
    sortBy: 'createdAt_ASC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [uiLabelFilter, setUiLabelFilter] = useState(initialUiLabelFilter);
  const [sortedInfo, setSortedInfo] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [languageLoading, setLanguageLoading] = useState(false);
  const [languageIsEnd, setLanguageIsEnd] = useState(false);
  const [languageDebounceCall, setLanguageDebounceCall] = useState(0);
  const initialLanguageFilter = {
    skip: 0,
    limit: 10
  };
  let languageDebounceJob;

  const history = useHistory();

  const { loading: organizationsDataLoading } = useQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { data: { skip: 0 } },
    onCompleted(res) {
      setOrganizationData(
        map(res?.organizations?.data, (item) => ({
          label: item?.name,
          value: item?.id
        }))
      );
    },
    onError() {}
  });

  const [fetchLanguages] = useLazyQuery(GET_LANGUAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: initialLanguageFilter
    },
    onCompleted: (res) => {
      if (res?.abConstants?.data?.length < SKIP_RECORD) {
        setLanguageIsEnd(true);
      }
      setLanguageData(uniq([...languageData, ...res?.abConstants?.data]));
      setLanguageLoading(false);
    },
    onError() {
      setLanguageLoading(false);
    }
  });

  const [fetchUiLabels, { data, loading }] = useLazyQuery(GET_UILABELS, {
    fetchPolicy: 'network-only',
    variables: { filter: initialUiLabelFilter },
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.uilabels?.total
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchUiLabels({ variables: { filter: initialUiLabelFilter } });
    fetchLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setUiLabelFilter({
      ...uiLabelFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUiLabelFilter({
        ...uiLabelFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: `${sorter?.field}_${
          sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }`
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: `${sorter?.field}_${
              sorter?.order === 'ascend' ? 'ASC' : 'DESC'
            }`
          }
        }
      });
    } else {
      setUiLabelFilter({
        ...uiLabelFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: 'createdAt_ASC'
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_ASC'
          }
        }
      });
    }
  };

  const handleAppFilter = (value) => {
    if (isEmpty(value) || !value) {
      setUiLabelFilter({
        ...uiLabelFilter,
        where: {
          ...uiLabelFilter?.where,
          organizationIds: []
        }
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            where: {
              ...uiLabelFilter?.where,
              organizationIds: []
            }
          }
        }
      });
    } else {
      setUiLabelFilter({
        ...uiLabelFilter,
        where: {
          ...uiLabelFilter?.where,
          organizationIds: value
        }
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            where: {
              ...uiLabelFilter?.where,
              organizationIds: value
            }
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    if (!value) {
      setUiLabelFilter({
        ...uiLabelFilter,
        skip: 0,
        search: ''
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            skip: 0,
            search: ''
          }
        }
      });
    } else {
      setUiLabelFilter({
        ...uiLabelFilter,
        skip: 0,
        search: trim(value)
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            skip: 0,
            search: trim(value)
          }
        }
      });
    }
  };

  const onLanguageScroll = (event) => {
    if (languageDebounceJob) {
      languageDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    languageDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !languageIsEnd) {
        setLanguageLoading(true);
        setLanguageDebounceCall((prevState) => prevState + 1);
        fetchLanguages({
          variables: {
            where: {
              ...initialLanguageFilter,
              skip: (languageDebounceCall + 1) * SKIP_RECORD
            }
          }
        });
      }
    }, 500);
    languageDebounceJob();
  };

  const handleLanguageChange = (value) => {
    if (!value) {
      setUiLabelFilter({
        ...uiLabelFilter,
        where: {
          ...uiLabelFilter?.where,
          languageValue: ''
        }
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            where: {
              ...uiLabelFilter?.where,
              languageValue: ''
            }
          }
        }
      });
    } else {
      setUiLabelFilter({
        ...uiLabelFilter,
        where: {
          ...uiLabelFilter?.where,
          languageValue: value
        }
      });
      fetchUiLabels({
        variables: {
          filter: {
            ...uiLabelFilter,
            where: {
              ...uiLabelFilter?.where,
              languageValue: value
            }
          }
        }
      });
    }
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      width: '500px',
      key: 'key',
      sorter: true,
      sortOrder: sortedInfo?.field === 'key' && sortedInfo?.order
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '1000px',
      key: 'value',
      sorter: true,
      sortOrder: sortedInfo?.field === 'value' && sortedInfo?.order
    },
    {
      title: 'GroupKey',
      dataIndex: 'groupKey',
      width: '400px',
      key: 'groupKey',
      sorter: true,
      sortOrder: sortedInfo?.field === 'groupKey' && sortedInfo?.order
    },
    {
      title: 'Language',
      dataIndex: 'languageValue',
      width: '300px',
      key: 'languageValue'
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      width: '400px',
      key: 'organizationName'
    },
    {
      title: 'Action',
      width: '500px',
      key: 'actions',
      render: (record) => {
        return (
          <div className="d-flex">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() =>
                history.push(`${ROUTES.UILABELS}/edit/${record?.id}`)
              }
            />
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Portal portalId="uilabel-add">
        <SearchComponent className="mr-10 h-35" getData={handleSearch} />
        <SelectComponent
          placeholder="Language"
          allowClear
          showArrow
          className="language-select h-35"
          loading={languageLoading}
          onChange={handleLanguageChange}
          onPopupScroll={onLanguageScroll}
          notFoundContent={
            languageLoading ? (
              <div className="d-flex justify-center">
                <Spin size="small" />
              </div>
            ) : undefined
          }
        >
          {map(languageData, (language) => {
            return (
              <Select.Option key={language?.key} value={language?.value}>
                {language?.value}
              </Select.Option>
            );
          })}
        </SelectComponent>
        <SelectComponent
          placeholder="Apps"
          allowClear
          showArrow
          loading={organizationsDataLoading}
          notFoundContent={
            organizationsDataLoading ? (
              <div className="d-flex justify-center">
                <Spin size="small" />
              </div>
            ) : undefined
          }
          mode="multiple"
          onChange={handleAppFilter}
          options={organizationData}
          showSearch={false}
          className="app-select"
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(`${ROUTES.UILABELS}/add`)}
        >
          Add UiLabel
        </Button>
      </Portal>
      <CommonTable
        loading={loading}
        columns={columns}
        data={data?.uilabels?.data || []}
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default UiLabelsList;
