import { useLazyQuery } from '@apollo/client';
import { Form, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';
import { ORGANIZATIONS } from '../graphql/Queries';

const { required } = formValidatorRules;

const NotificationForm = (props) => {
  const { form, onSendNotification } = props;
  const [organizations, setOrganizations] = useState([]);
  const debounceJob = useRef();

  const [
    fetchOrganizationsData,
    { loading: organizationsDataLoading }
  ] = useLazyQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(data) {
      if (data?.organizations?.data?.length) {
        const tempOrganizations = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data?.organizations?.data?.length; i++) {
          const organization = data?.organizations?.data?.[i];
          tempOrganizations.push({
            label: organization?.name,
            value: organization?.id
          });
        }
        setOrganizations(tempOrganizations);
      } else {
        setOrganizations([]);
      }
    }
  });

  useEffect(() => {
    fetchOrganizationsData({ variables: { data: { skip: 0, limit: 50 } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function debounceFetchOrganizations(search) {
    if (debounceJob?.current) {
      debounceJob?.current?.cancel();
    }
    debounceJob.current = debounce(() => {
      fetchOrganizationsData({
        variables: { data: { skip: 0, limit: 50, search } }
      });
    }, 500);

    debounceJob?.current();
  }
  return (
    <Form
      form={form}
      initialValues={{}}
      layout="vertical"
      onFinish={onSendNotification}
    >
      <Form.Item rules={[required]} name="title" label="Title">
        <InputComponent allowClear placeholder="Title" />
      </Form.Item>
      <Form.Item rules={[required]} name="description" label="Description">
        <InputComponent type="textArea" allowClear placeholder="Description" />
      </Form.Item>
      <Form.Item rules={[required]} name="organizationIds" label="Apps">
        <SelectComponent
          placeholder="Apps"
          allowClear
          mode="multiple"
          filterOption={false}
          showArrow
          loading={organizationsDataLoading}
          onSearch={(search) => debounceFetchOrganizations(search)}
          notFoundContent={
            organizationsDataLoading ? (
              <div className="d-flex justify-center">
                <Spin size="small" />
              </div>
            ) : undefined
          }
          options={organizations}
        />
      </Form.Item>
    </Form>
  );
};

export default NotificationForm;
