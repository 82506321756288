import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD, TOKEN_VALIDATION } from './graphql/Mutations';

const { required } = formValidatorRules;

const ResetPassword = (props) => {
  const { history, history: { location: { search } = {} } = {} } = props;

  const [resetPasswordMutate, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError() {}
    }
  );
  const [tokenValidationMutate] = useMutation(TOKEN_VALIDATION, {
    onError() {
      history.push(ROUTES.LOGIN);
    }
  });

  const getQuery = () => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const resetToken = getQuery()?.get('token');
    tokenValidationMutate({ variables: { resetToken } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async ({ password }) => {
    try {
      const resetToken = getQuery()?.get('token');
      await tokenValidationMutate({ variables: { resetToken } });
      await resetPasswordMutate({ variables: { token: resetToken, password } });
      history.replace(ROUTES.LOGIN);
    } catch (e) {
      if (get(e, 'response.data.message')) {
        message.error(e.response.data.message);
      } else if (e && e.message) {
        message.error(e.message);
      } else {
        message.error('Something went wrong');
      }
    }
  };

  return (
    <div className="gx-login-container">
      <Spin spinning={resetPasswordLoading} wrapperClassName="gx-login-content">
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Form
          name="change-password"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="password" rules={[required]}>
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="retype-password"
            rules={[
              required,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value !== getFieldValue('password')) {
                    return Promise.reject(new Error('Passwords do not match'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Retype password"
            />
          </Form.Item>
          <div className="d-flex">
            <Form.Item>
              <Button
                loading={resetPasswordLoading}
                type="primary"
                className="mr-5"
                htmlType="submit"
              >
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ResetPassword;
