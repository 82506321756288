import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_LANGUAGES = gql`
  query abConstants($where: abConstantsInput) {
    abConstants(where: $where) {
      total
      data {
        id
        key
        value
        language
        groupKey
      }
    }
  }
`;
