import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { CHAPLAINS } from '../graphql/Queries';

const defaultPageSize = 10;

const ChaplainList = () => {
  const initialChaplainFilter = {
    skip: 0,
    limit: defaultPageSize,
    sortBy: 'createdAt_DESC'
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [chaplinFilter, setChaplainFilter] = useState(initialChaplainFilter);

  const [fetchChaplainData, { loading, data }] = useLazyQuery(CHAPLAINS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: defaultPageSize,
        total: res?.chaplains?.total
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchChaplainData({ variables: { data: chaplinFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setChaplainFilter({
      ...chaplinFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setChaplainFilter({
        ...chaplinFilter,
        sortBy:
          sorter?.order === 'ascend'
            ? `${sorter?.field}_ASC`
            : `${sorter?.field}_DESC`
      });
      fetchChaplainData({
        variables: {
          data: {
            ...chaplinFilter,
            skip,
            limit: pagination.pageSize,
            sortBy:
              sorter?.order === 'ascend'
                ? `${sorter?.field}_ASC`
                : `${sorter?.field}_DESC`
          }
        }
      });
    } else {
      setChaplainFilter({
        ...chaplinFilter,
        sortBy: 'createdAt_DESC'
      });
      fetchChaplainData({
        variables: {
          data: {
            ...chaplinFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const onSearchChange = async (value) => {
    const searchValue = value.trim(' ');
    setChaplainFilter({ ...chaplinFilter, skip: 0, search: searchValue });
    fetchChaplainData({
      variables: { data: { ...chaplinFilter, skip: 0, search: searchValue } }
    });
  };

  const renderCommaSeparatedList = (datalist, config) => {
    const { isObject = false, key } = config || {};

    if (!datalist || !datalist.length) {
      return '';
    }

    let commaSeparatedList = datalist.map((dataItem) => {
      if (!isObject) {
        return dataItem;
      }

      return dataItem[key];
    });

    commaSeparatedList = commaSeparatedList.join(', ');
    return <div className="comma-separated-list">{commaSeparatedList}</div>;
  };

  const columns = [
    {
      title: 'UID',
      dataIndex: 'refId',
      key: 'refId',
      width: 350,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'refId' && sortedInfo?.order,
      render: (value) => {
        return value || '-';
      }
    },
    {
      title: 'FIRSTNAME',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 350,
      className: 'wb-word',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order
    },
    {
      title: 'LASTNAME',
      dataIndex: 'lastName',
      width: 350,
      className: 'wb-word',
      key: 'lastName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'lastName' && sortedInfo?.order
    },
    {
      title: 'COMPANIES',
      dataIndex: 'companies',
      width: 450,
      className: 'wb-word',
      key: 'companies',
      render: (companies) =>
        renderCommaSeparatedList(companies, { isObject: true, key: 'name' }) ||
        '--'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order
    },
    {
      title: 'PHONE NUMBER',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNumber' && sortedInfo?.order,
      render: (value) => {
        return value || '--';
      }
    }
  ];

  return (
    <>
      <Portal portalId="chaplain-search-wrapper">
        <div className="d-flex">
          <SearchComponent
            id="search-container-id"
            name="Chaplain"
            getData={onSearchChange}
          />
        </div>
      </Portal>
      <CommonTable
        loadingData={loading}
        columns={columns}
        data={data?.chaplains?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </>
  );
};

export default withRouter(ChaplainList);
