import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      token
      refreshToken
      user {
        id
        firstName
        lastName
        email
        emailVerified
        disabled
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(data: { email: $email }) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(data: { token: $token, password: $password }) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation isValidToken($resetToken: String!) {
    isValidToken(data: { resetToken: $resetToken }) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      data: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      status
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
      status
    }
  }
`;
