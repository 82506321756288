import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CompanyList from '../components/CompanyList';

function Companies() {
  return (
    <Card>
      <div className="d-flex align-center justify-between">
        <h1>Companies</h1>
        <div id="company-search-wrapper" />
      </div>
      <hr />
      <CompanyList />
    </Card>
  );
}

export default withRouter(Companies);
