import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const RESOURCES = gql`
  query resources($data: ResourceFilter!) {
    resources(data: $data) {
      total
      data {
        id
        content {
          title
          description
          language
          url
        }
        averageRating
        type
        organizationIds
        organizations {
          id
          name
        }
        isActive
        thumbnail
        order
        comment
        companyIds
        companies {
          id
          name
        }
        updatedAt
      }
    }
  }
`;

export const ORGANIZATIONS = gql`
  query organizations($data: ResourceFilter!) {
    organizations(data: $data) {
      total
      data {
        id
        name
        organizationId
        newsLetters {
          language
          languageValue
          url
        }
      }
    }
  }
`;

export const COMPANIES = gql`
  query companies($data: CompanyFilter!) {
    companies(data: $data) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const LANGUAGES = gql`
  query abConstants($where: abConstantsInput) {
    abConstants(where: $where) {
      total
      data {
        key
        value
        language
      }
    }
  }
`;

export const RESOURCES_RATINGS = gql`
  query resourceRattings($data: ResourceRattingFilter!) {
    resourceRattings(data: $data) {
      data {
        name
        email
        resourceId
        comment
        rate
        language
        updatedAt
      }
    }
  }
`;
