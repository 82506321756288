import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row, Select, Spin, Upload } from 'antd';
import { debounce, filter, nth, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from '../../../apollo';
import { ALLOW_IMAGE_TYPES } from '../../../common/constants';
import { formValidatorRules, getBase64 } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';
import { COMPANIES, LANGUAGES, ORGANIZATIONS } from '../graphql/Queries';

const { required, number } = formValidatorRules;
const { Option } = Select;
const searchLimit = 50;

const ResourceForm = (props) => {
  const {
    form,
    isEdit,
    resourceData,
    onResourceSubmitFinish,
    createResourceLoading,
    updateResourceLoading,
    history,
    setImageUrl,
    imageUrl,
    setFileObj
  } = props;

  const [organizations, setOrganizations] = useState(
    resourceData?.organizations?.map((organization) => ({
      label: organization?.name,
      value: organization?.id
    })) || []
  );

  const [companies, setCompanies] = useState(
    resourceData?.companies?.map((company) => ({
      label: company?.name,
      value: company?.id
    })) || []
  );
  const [languages, setLanguages] = useState([]);
  let languageSelected = [];
  const debounceJob = useRef();

  const [
    fetchOrganizationsData,
    { loading: organizationsDataLoading }
  ] = useLazyQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(data) {
      if (data?.organizations?.data?.length) {
        const tempOrganizations = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data?.organizations?.data?.length; i++) {
          const organization = data?.organizations?.data?.[i];
          tempOrganizations.push({
            label: organization?.name,
            value: organization?.id
          });
        }
        const selectedOrganizations =
          resourceData?.organizations?.map((organization) => ({
            label: organization?.name,
            value: organization?.id
          })) || [];
        setOrganizations(
          uniqBy([...selectedOrganizations, ...tempOrganizations], 'value')
        );
      } else {
        setOrganizations([]);
      }
    }
  });

  const [fetchCompaniesData, { loading: companiesDataLoading }] = useLazyQuery(
    COMPANIES,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted(data) {
        if (data?.companies?.data?.length) {
          const tempCompanies = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data?.companies?.data?.length; i++) {
            const company = data?.companies?.data?.[i];
            tempCompanies.push({ label: company?.name, value: company?.id });
          }
          const selectedCompanies =
            resourceData?.companies?.map((company) => ({
              label: company?.name,
              value: company?.id
            })) || [];
          setCompanies(
            uniqBy([...selectedCompanies, ...tempCompanies], 'value')
          );
        } else {
          setCompanies([]);
        }
      }
    }
  );

  const { loading: languagesDataLoading } = useQuery(LANGUAGES, {
    fetchPolicy: 'network-only',
    variables: { where: { limit: searchLimit } },
    onCompleted: (data) => {
      let tempLanguages = [];
      if (data?.abConstants?.data?.length) {
        tempLanguages = data?.abConstants?.data?.map((language) => {
          return {
            label: language?.value,
            value: language?.key
          };
        });
      }
      setLanguages(tempLanguages);
    },
    onError() {}
  });

  useEffect(() => {
    fetchOrganizationsData({
      variables: { data: { skip: 0, limit: searchLimit } }
    });
    fetchCompaniesData({
      variables: { data: { skip: 0, limit: searchLimit } }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function debounceFetchOrganizations(search) {
    if (debounceJob?.current) {
      debounceJob?.current?.cancel();
    }
    debounceJob.current = debounce(() => {
      fetchOrganizationsData({
        variables: { data: { skip: 0, limit: searchLimit, search } }
      });
    }, 500);

    debounceJob?.current();
  }

  function debounceFetchCompanies(search) {
    if (debounceJob?.current) {
      debounceJob?.current?.cancel();
    }
    debounceJob.current = debounce(() => {
      fetchCompaniesData({
        variables: { data: { skip: 0, limit: searchLimit, search } }
      });
    }, 500);

    debounceJob?.current();
  }

  useEffect(() => {
    if (resourceData?.thumbnail) {
      setImageUrl(resourceData?.thumbnail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceData]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...resourceData,
          content: resourceData?.content || [{}],
          isActive: resourceData?.content ? resourceData?.isActive : true
        }}
        layout="horizontal"
        labelAlign="left"
        className="resource-form mt-25"
        labelCol={{ md: { span: 5 }, sm: { span: 6 } }}
        wrapperCol={{ md: { span: 18 }, sm: { span: 18 } }}
        onFinish={onResourceSubmitFinish}
      >
        <Row gutter={16}>
          <Col md={10} sm={24} xs={24}>
            <Form.Item name="type" label="Resource Type" rules={[required]}>
              <SelectComponent placeholder="Resource Type" allowClear>
                <Option value="ARTICLE">ARTICLE</Option>
                <Option value="AUDIO">AUDIO</Option>
                <Option value="VIDEO">VIDEO</Option>
                <Option value="BOOK">BOOK</Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col md={10} sm={24} xs={24}>
            <Form.Item name="organizationIds" label="Apps" rules={[required]}>
              <SelectComponent
                placeholder="Apps"
                allowClear
                mode="multiple"
                filterOption={false}
                showArrow
                loading={organizationsDataLoading}
                onSearch={(search) => debounceFetchOrganizations(search)}
                notFoundContent={
                  organizationsDataLoading ? (
                    <div className="d-flex justify-center">
                      <Spin size="small" />
                    </div>
                  ) : undefined
                }
                options={organizations}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={10} sm={24} xs={24}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              className="upload-image"
            >
              <Upload
                name="logo"
                accept={ALLOW_IMAGE_TYPES}
                listType="picture"
                multiple={false}
                beforeUpload={() => {
                  return false;
                }}
                showUploadList={false}
                onChange={(info) => {
                  const splitData = info?.file?.type?.split('/');
                  const imageType = nth(splitData, 1);
                  const isImage = ALLOW_IMAGE_TYPES.includes(imageType);

                  if (isImage) {
                    getBase64(info?.file, (image) => setImageUrl(image));
                    setFileObj(info?.file);
                  } else {
                    toast({
                      message: 'You can only upload Image',
                      type: 'error'
                    });
                  }
                }}
              >
                {imageUrl && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a
                      href={resourceData?.thumbnail}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imageUrl}
                        alt="thumbnail"
                        height="70px"
                        width="70px"
                      />
                    </a>
                  </div>
                )}
                <Button
                  type="primary"
                  className={`${imageUrl && 'upload-button'}`}
                >
                  {imageUrl ? 'Replace File' : 'Select File'}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col md={10} sm={24}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={10} sm={24} xs={24}>
            <Form.Item name="companyIds" label="Companies">
              <SelectComponent
                placeholder="Companies"
                allowClear
                mode="multiple"
                filterOption={false}
                showArrow
                loading={companiesDataLoading}
                onSearch={(search) => debounceFetchCompanies(search)}
                notFoundContent={
                  companiesDataLoading ? (
                    <div className="d-flex justify-center">
                      <Spin size="small" />
                    </div>
                  ) : undefined
                }
                options={companies}
              />
            </Form.Item>
          </Col>
        </Row>
        {isEdit && (
          <Row gutter={16}>
            <Col md={10} sm={24} xs={24}>
              <Form.Item name="order" label="Resource order" rules={[number]}>
                <InputComponent placeholder="Resource Order" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row className="mt-10">
          <Col span={24}>
            <h4 className="font-21 font-weight-600 mb-16">Resource Content</h4>
          </Col>
          <Col span={24}>
            <Form.List name="content">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Row key={key}>
                        <Col md={20}>
                          <Row gutter={16}>
                            <Col md={12} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'language']}
                                fieldKey={[fieldKey, 'language']}
                                label="Language"
                                rules={[
                                  required,
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (index === 0 || !value) {
                                        return Promise.resolve();
                                      }
                                      languageSelected = filter(
                                        getFieldValue('content'),
                                        (e) => {
                                          return e?.language === value;
                                        }
                                      );
                                      if (languageSelected?.length >= 2) {
                                        return Promise.reject(
                                          new Error('Must Not Be Repeated')
                                        );
                                      }
                                      return Promise.resolve();
                                    }
                                  })
                                ]}
                              >
                                <SelectComponent
                                  placeholder="Language"
                                  allowClear
                                  showArrow
                                  loading={languagesDataLoading}
                                  notFoundContent={
                                    languagesDataLoading ? (
                                      <div className="d-flex justify-center">
                                        <Spin size="small" />
                                      </div>
                                    ) : undefined
                                  }
                                  options={languages}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'title']}
                                fieldKey={[fieldKey, 'title']}
                                label="Title"
                                rules={[required]}
                              >
                                <InputComponent placeholder="Title" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col md={12} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'url']}
                                fieldKey={[fieldKey, 'url']}
                                label="Resource URL"
                                rules={[
                                  required,
                                  {
                                    type: 'url',
                                    message: 'should be a valid url'
                                  }
                                ]}
                              >
                                <InputComponent
                                  type="textArea"
                                  placeholder="Resource URL"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'description']}
                                fieldKey={[fieldKey, 'description']}
                                label="Description"
                              >
                                <InputComponent
                                  type="textArea"
                                  placeholder="Description"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} sm={24} xs={24}>
                          <Button
                            type="danger"
                            icon={<DeleteOutlined />}
                            disabled={fields.length < 2}
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    )
                  )}
                  <Row>
                    <Col md={{ offset: 20, span: 4 }} xs={24} sm={24}>
                      <Form.Item>
                        <Button onClick={() => add()} icon={<PlusOutlined />} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row>
          <Col md={10} sm={24} xs={24}>
            <div className="d-flex">
              <Form.Item className="mr-5">
                <Button
                  loading={createResourceLoading || updateResourceLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? 'Save' : 'Add'}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => history.goBack()}>Back</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withRouter(ResourceForm);
