import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WEBINARS = gql`
  query webinars($data: WebinarFilter!) {
    webinars(data: $data) {
      total
      data {
        id
        title
        description
        startDate
        endDate
        refData
        organizationIds
        organizations {
          id
          name
        }
        webinarId
        url
        companyIds
        companies {
          id
          name
          locationId
        }
        locationIds
        locations {
          id
        }
      }
    }
  }
`;

export const ORGANIZATIONS = gql`
  query organizations($data: ResourceFilter!) {
    organizations(data: $data) {
      total
      data {
        id
        name
        organizationId
      }
    }
  }
`;

export const COMPANIES = gql`
  query companies($data: CompanyFilter!) {
    companies(data: $data) {
      total
      data {
        id
        name
        locationId
      }
    }
  }
`;

export const LOCATIONS = gql`
  query companyLocations($data: CompanyFilter!) {
    companyLocations(data: $data) {
      total
      data {
        locationId
      }
    }
  }
`;
