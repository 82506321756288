import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Input, message, Modal, Radio, Rate, Space, Tag } from 'antd';
import { clone, get, isEmpty, map, slice } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { DELETE_RESOURCE } from '../graphql/Mutations';
import { ORGANIZATIONS, RESOURCES } from '../graphql/Queries';
import CompaniesModal from './CompaniesModal';

const { confirm } = Modal;

const ResourceList = (props) => {
  const { history } = props;

  const initialResourceFilter = {
    skip: 0,
    limit: 10,
    sortBy: 'createdAt_DESC'
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [resourceFilter, setResourceFilter] = useState(initialResourceFilter);
  const [appFilter, setAppFilter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);

  const [
    deleteResourceMutate,
    { loading: deleteResourceLoading }
  ] = useMutation(DELETE_RESOURCE, {
    onError() {}
  });

  const { loading: organizationsDataLoading } = useQuery(ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    variables: { data: { skip: 0, limit: 50 } },
    onCompleted: (data) => {
      let filters = [];
      if (data?.organizations?.data?.length) {
        filters = data?.organizations?.data?.map((organization) => {
          return {
            text: organization?.name,
            value: organization?.id
          };
        });
      }
      setAppFilter([...clone(appFilter), ...filters]);
    },
    onError() {}
  });

  const [fetchResourceData, { loading, data, refetch }] = useLazyQuery(
    RESOURCES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.resources?.total
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchResourceData({ variables: { data: resourceFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setResourceFilter({
      ...resourceFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setResourceFilter({
        ...resourceFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: `${sorter?.columnKey}_${
          sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }`
      });
      fetchResourceData({
        variables: {
          data: {
            ...resourceFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: `${sorter?.columnKey}_${
              sorter?.order === 'ascend' ? 'ASC' : 'DESC'
            }`
          }
        }
      });
    } else {
      setResourceFilter({
        ...resourceFilter,
        sortBy: 'createdAt_DESC'
      });
      fetchResourceData({
        variables: {
          data: {
            ...resourceFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC'
          }
        }
      });
    }
  };

  const onSearchChange = async (value) => {
    const searchValue = value.trim(' ');
    setResourceFilter({ ...resourceFilter, skip: 0, search: searchValue });
    fetchResourceData({
      variables: { data: { ...resourceFilter, skip: 0, search: searchValue } }
    });
  };

  const handleFilter = (selectedKeys, confirmFilter, dataIndex) => {
    confirmFilter();
    const { where = {} } = resourceFilter;
    const filterData = {
      ...resourceFilter,
      skip: 0,
      where: {
        ...where,
        ...(dataIndex === 'order' && {
          order: parseInt(selectedKeys, 10)
        }),
        ...(dataIndex === 'organizations' && {
          organizationIds: [selectedKeys]
        }),
        ...(dataIndex === 'type' && {
          type: selectedKeys
        }),
        ...(dataIndex === 'averageRating' && {
          averageRating: parseFloat(selectedKeys)
        })
      }
    };
    setResourceFilter(filterData);
    fetchResourceData({
      variables: {
        data: filterData
      }
    });
  };

  function handleFilterReset(clearFilters, dataIndex) {
    clearFilters();
    const tempFilter = clone(resourceFilter);
    const { where = {} } = tempFilter;
    if (where?.order && dataIndex === 'order') {
      delete where?.order;
    }
    if (where?.organizationIds && dataIndex === 'organizations') {
      delete where?.organizationIds;
    }
    if (where?.type && dataIndex === 'type') {
      delete where?.type;
    }
    if (where?.averageRating && dataIndex === 'averageRating') {
      delete where?.averageRating;
    }
    setResourceFilter({
      ...tempFilter,
      skip: 0,
      where
    });
    fetchResourceData({
      variables: { data: { ...tempFilter, skip: 0, where } }
    });
  }

  function radioFilter(selectedKeys, setSelectedKeys, dataIndex) {
    return (
      <Radio.Group
        onChange={(e) => setSelectedKeys(e?.target?.value ?? '')}
        value={selectedKeys}
      >
        <Space direction="vertical">
          {dataIndex === 'organizations' &&
            appFilter?.map((filter) => (
              <Radio key={filter?.value} value={filter?.value}>
                {filter?.text}
              </Radio>
            ))}
          {dataIndex === 'type' && (
            <>
              <Radio value="AUDIO">Audio</Radio>
              <Radio value="ARTICLE">Article</Radio>
              <Radio value="BOOK">Book</Radio>
              <Radio value="VIDEO">Video</Radio>
            </>
          )}
        </Space>
      </Radio.Group>
    );
  }

  function inputFilter(
    selectedKeys,
    setSelectedKeys,
    dataIndex,
    confirmFilter
  ) {
    return (
      <Input
        placeholder={`Search ${dataIndex}`}
        type="number"
        value={selectedKeys}
        onChange={(e) => setSelectedKeys(e.target.value ?? '')}
        onPressEnter={() =>
          handleFilter(selectedKeys, confirmFilter, dataIndex)
        }
        className="resource-input-filter"
      />
    );
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm: confirmFilter,
        clearFilters
      }) => (
        <div className="d-flex flex-vertical resource-appfilter-wrapper">
          {(dataIndex === 'organizations' || dataIndex === 'type') &&
          selectedKeys
            ? radioFilter(
                selectedKeys,
                setSelectedKeys,
                dataIndex,
                confirmFilter
              )
            : inputFilter(
                selectedKeys,
                setSelectedKeys,
                dataIndex,
                confirmFilter
              )}
          <div className="resource-appfilter-btn-wrapper">
            <Button
              id={`btn-${dataIndex}-search`}
              type="primary"
              onClick={() => {
                if (selectedKeys?.length > 0) {
                  handleFilter(selectedKeys, confirmFilter, dataIndex);
                } else {
                  confirmFilter();
                  setResourceFilter({
                    ...resourceFilter,
                    sortBy: 'createdAt_DESC'
                  });
                  fetchResourceData({
                    variables: {
                      data: {
                        ...resourceFilter,
                        sortBy: 'createdAt_DESC'
                      }
                    }
                  });
                }
              }}
              size="small"
              className="mr-5"
            >
              Ok
            </Button>
            <Button
              id={`btn-${dataIndex}-reset`}
              onClick={() => handleFilterReset(clearFilters, dataIndex)}
              size="small"
            >
              Reset
            </Button>
          </div>
        </div>
      )
    };
  }

  function handleResourceDelete(record) {
    const { id, content = [] } = record;
    confirm({
      centered: true,
      title: `You want to delete resource ${content?.[0]?.title}?`,
      icon: <DeleteOutlined />,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          const response = await deleteResourceMutate({
            variables: { where: id }
          });

          if (response?.data?.deleteResource) {
            refetch();
          }
        } catch (error) {
          if (get(error, 'response.data.message')) {
            message.error(error.response.data.message);
          } else if (error && error.message) {
            message.error(error.message);
          } else {
            message.error('Something went wrong');
          }
        }
      }
    });
  }

  const renderCommaSeparatedList = (datalist, config) => {
    const { isObject = false, key } = config || {};

    if (!datalist || !datalist.length) {
      return '';
    }

    let commaSeparatedList = datalist.map((dataItem) => {
      if (!isObject) {
        return dataItem;
      }

      return dataItem[key];
    });

    commaSeparatedList = commaSeparatedList.join(', ');
    return <div className="comma-separated-list">{commaSeparatedList}</div>;
  };

  const columns = [
    {
      title: 'ORDER',
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'order' && sortedInfo?.order,
      filterMultiple: false,
      ...getColumnSearchProps('order')
    },
    {
      title: 'TITLE',
      dataIndex: ['content', '0', 'title'],
      key: 'title',
      className: 'wb-word',
      width: 400,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order
    },
    {
      title: 'THUMBNAIL',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (thumbnail) =>
        (
          <img src={thumbnail} alt={thumbnail} className="resource-thumbnail" />
        ) || '--'
    },
    {
      title: 'DESCRIPTION',
      dataIndex: ['content', '0', 'description'],
      key: 'description',
      className: 'wb-word',
      width: 450,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'description' && sortedInfo?.order,
      render: (value) => {
        return value || '--';
      }
    },
    {
      title: 'APPS',
      dataIndex: 'organizations',
      key: 'organizations',
      filters: appFilter,
      filterMultiple: false,
      ...getColumnSearchProps('organizations'),
      render: (organizations) =>
        renderCommaSeparatedList(organizations, { isObject: true, key: 'name' })
    },
    {
      title: 'COMPANIES',
      dataIndex: 'companies',
      key: 'companies',
      render: (companies) => {
        if (isEmpty(companies)) {
          return '--';
        }
        return (
          <>
            {map(slice(companies, 0, 2), (item) => {
              return (
                <Tag className="mb-5" key={item?.id}>
                  {item?.name}
                </Tag>
              );
            })}{' '}
            {companies?.length > 2 && (
              <Button
                type="link"
                onClick={() => {
                  setShowModal(true);
                  setCompaniesData(companies);
                }}
              >
                {' '}
                ... + {companies?.length - 2} More
              </Button>
            )}
          </>
        );
      }
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      filterMultiple: false,
      ...getColumnSearchProps('type')
    },
    {
      title: 'ACTIVE',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (isActive) =>
        isActive ? (
          <CheckOutlined className="active-checkbox" />
        ) : (
          <CloseOutlined className="inactive" />
        )
    },
    {
      title: 'AVERAGE RATING',
      dataIndex: 'averageRating',
      key: 'averageRating',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'averageRating' && sortedInfo?.order,
      filterMultiple: false,
      ...getColumnSearchProps('averageRating'),
      render: (rating) => (
        <Rate
          className="rating"
          disabled
          allowHalf
          defaultValue={rating || 0}
        />
      )
    },
    {
      title: 'ACTIONS',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              type="link"
              id={`resource-action-edit-btn-${id}`}
              icon={<EditOutlined />}
              onClick={() => history.push(`${ROUTES.RESOURCES_EDIT}/${id}`)}
            />
            <Button
              type="link"
              danger
              id={`resource-action-delete-btn-${id}`}
              icon={<DeleteOutlined />}
              onClick={(e) => handleResourceDelete(record, e)}
            />
          </div>
        );
      }
    }
  ];

  return (
    <>
      {showModal && (
        <CompaniesModal
          showModal={showModal}
          setShowModal={setShowModal}
          companiesData={companiesData}
        />
      )}
      <Portal portalId="resource-search-wrapper">
        <div className="d-flex">
          <SearchComponent
            id="search-container-id"
            name="Resources"
            getData={onSearchChange}
          />
          <Button
            type="primary"
            className="resource-add-btn"
            icon={<PlusOutlined />}
            onClick={() => history.push(ROUTES.RESOURCES_ADD)}
          />
        </div>
      </Portal>
      <CommonTable
        loadingData={
          loading || deleteResourceLoading || organizationsDataLoading
        }
        tableClassName="resource-table"
        columns={columns}
        data={data?.resources?.data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </>
  );
};

export default withRouter(ResourceList);
