import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_UILABELS = gql`
  query uilabels($filter: UilabelFilter!) {
    uilabels(filter: $filter) {
      total
      data {
        id
        key
        value
        groupKey
        language
        organizationIds
        languageValue
        organizationName
      }
    }
  }
`;
