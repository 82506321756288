import { Card } from 'antd';
import React from 'react';
import UiLabelsList from './components/UiLabelsList';

const UiLabels = () => {
  return (
    <Card>
      <div className="d-flex align-center justify-between">
        <h1>UI Labels</h1>
        <div id="uilabel-add" className="d-flex" />
      </div>
      <hr />
      <UiLabelsList />
    </Card>
  );
};

export default UiLabels;
