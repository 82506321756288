import { LeftOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import UiLabelForm from '../components/UiLabelForm';

const UiLabelAdd = () => {
  const history = useHistory();
  return (
    <Card>
      <h3 className="font-24 font-weight-600">
        <LeftOutlined className="mr-5" onClick={() => history.goBack()} />
        <span>Add UiLabel</span>
      </h3>
      <hr />
      <UiLabelForm />
    </Card>
  );
};

export default UiLabelAdd;
