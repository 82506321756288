import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, message, Spin } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgotPassword = (props) => {
  const { history } = props;
  const [
    forgotPasswordMutate,
    { loading: forgotPasswordLoading }
  ] = useMutation(FORGOT_PASSWORD, {
    onError() {}
  });
  const onFinish = async (values) => {
    try {
      await forgotPasswordMutate({ variables: values });
      history.push(ROUTES.LOGIN);
    } catch (e) {
      if (get(e, 'response.data.message')) {
        message.error(e.response.data.message);
      } else if (e && e.message) {
        message.error(e.message);
      } else {
        message.error('Something went wrong');
      }
    }
  };
  return (
    <div className="gx-login-container">
      <Spin
        spinning={forgotPasswordLoading}
        wrapperClassName="gx-login-content"
      >
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            Don't worry. Recovering the password is easy.Just tell us the email.
          </p>
        </div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required, email]}>
            <InputComponent
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>
          <div className="d-flex">
            <Form.Item>
              <Button
                type="primary"
                loading={forgotPasswordLoading}
                className="mr-5"
                htmlType="submit"
              >
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ForgotPassword;
