import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ResourceAdd from './pages/ResourceAdd';
import ResourceEdit from './pages/ResourceEdit';
import Resources from './pages/Resources';
import './resources.less';

const ResourceWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES.RESOURCES_EDIT}/:id`}
        component={ResourceEdit}
      />
      <Route exact path={ROUTES.RESOURCES_ADD} component={ResourceAdd} />
      <Route path={ROUTES.RESOURCES} component={Resources} />
    </Switch>
  );
};

export default ResourceWrapper;
