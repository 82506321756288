import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Upload } from 'antd';
import { map, nth } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from '../../../apollo';
import { formValidatorRules, getBase64 } from '../../../common/utils';
import SelectComponent from '../../../components/SelectComponent';

const { Option } = Select;
const { required } = formValidatorRules;

const OrganizationForm = (props) => {
  const {
    form,
    organizationData,
    onOrganizationSubmitFinish,
    history,
    fileUrl,
    setFileUrl,
    fileObj,
    setFileObj,
    loading,
    languages
  } = props;

  return (
    <Form
      form={form}
      initialValues={organizationData}
      layout="horizontal"
      labelAlign="left"
      className="mt-25"
      labelCol={{ md: { span: 5 }, sm: { span: 6 } }}
      wrapperCol={{ md: { span: 18 }, sm: { span: 18 } }}
      onFinish={onOrganizationSubmitFinish}
    >
      <Row gutter={16}>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="language" label="Language" rules={[required]}>
            <SelectComponent placeholder="Language" allowClear>
              {map(languages, (language) => (
                <Option key={language?.key} value={language?.value}>
                  {language?.value}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Col>
        <Col md={10} sm={24} xs={24}>
          <Form.Item name="upload" label="File" rules={[required]}>
            <Upload
              name="logo"
              accept=".pdf"
              listType="picture"
              multiple={false}
              beforeUpload={() => {
                return false;
              }}
              showUploadList={false}
              onChange={(info) => {
                const splitData = info?.file?.type?.split('/');
                const isPdf = nth(splitData, 1);
                if (isPdf === 'pdf') {
                  getBase64(info?.file, (pdf) => setFileUrl(pdf));
                  setFileObj(info?.file);
                } else {
                  toast({ message: 'You can only upload PDF', type: 'error' });
                }
              }}
            >
              <Button type="primary">
                {fileUrl ? 'Replace File' : 'Select File'}
              </Button>
            </Upload>
          </Form.Item>
          {fileUrl && (
            <h4
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FilePdfOutlined />
              {fileObj?.name}
            </h4>
          )}
        </Col>
      </Row>
      <Row />
      <Row>
        <Col md={10} sm={24} xs={24}>
          <div className="d-flex">
            <Form.Item className="mr-5">
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => history.goBack()}>Back</Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(OrganizationForm);
