import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './companies.less';
import Companies from './pages/Companies';

const CompanyWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.COMPANIES} component={Companies} />
    </Switch>
  );
};

export default CompanyWrapper;
